// @flow

/**
 * Thematic Landing Pages: Feature Deescription
 *
 * We want to landing pages for genres or themes. For example, we might have a celebrity voices landing page featuring our audiobooks with celebrities.
 * Or, maybe contemporary romance with further break out themes.
 *
 * A Landing Page might look like this:
 *
 * Hero component
 * - Features audiobooks in the genre, allows preview and then click to those audiobooks
 * - If user is not subscribed, a cta with seamless sign up/subscription on the page
 *
 * Jump Back In
 * - Highlights audiobooks in the genre the user has started but not finished or have new eipsodes
 *
 * Meet Our Characters
 * - Features characters in the genre
 *
 * Genres or Themes Inside the Genre Rows
 * - Contemporary Romance might have break out sections for queer, wlw, etc or queer romance might have break outs for Gay, WLW, etc
 *
 * Popular Character Experiences
 * - Most poppular episodes in the genre
 *
 * Coming Soon
 * - Upcoming episodes in the genre
 *
 * Books
 * - Call out for books in the genre
 *
 * User Quotes
 * - Carousel of quotes from users who loved characters
 *
 * Blog Spotlight
 * - Blog posts about the genre
 *
 * We'll want to use our gradient color background to distinguish these sections, similar to our home page. Each of these sections could be turned off OR modified via URL params
 * - For instance, we might want to test the full page versus just the hero component with characters.
 * - We might want to test two different versions of the hero component
 * - So, we should identify a url param for each of these sections and set it to a number, with 0 being off and 1..n being test permutations, default to on
 * - Then we build HOC for each url param that wraps the component
 *
 */

import type { ReviewType } from '@components/ReviewSlider'
import type { SeriesInfoPropsType } from './Series'
import { convertSeriesToSeriesInfoProps, getFirstTrack } from './Series'
import { getLibrary, getPopularSeries, getPopularTacks, getThemes } from '../services/dipseaApi'
import type { PopularityBySeriesType } from '../services/dipseaApi'
import { convertTrackToProps, reducePopularity } from './Track'
import { fetchBlogArticlesByTheme } from '../services/contentfulPosts'
import type { DeepLinkedCardsCarouselPropsType, DeepLinkedCardsItemPropsType, HorizontalMediumTrackRowPropsType } from './Discover'
import { convertDeepLink } from './helpers'
import type { DipseaContentType } from '../flowTypes'
import type { TrackPropsType, PopularityType, CarouselType } from './Track'
import type { HomePageSpotLightType } from '../services/contentfulPosts'
import { isEmpty } from 'lodash'

export type ThematicTagInfoType = {
  id: string,
  title: string,
  description: string | null, // max 450
  logLine: string | null, // max 150 characters
  slug: string,
  imageUrl: string | null,
  audioBooks?: string[]
}

export type ThemeObjectType = {
  [key: string]: ThematicTagInfoType
}

export async function getThematicTagSlugs (): Promise<string[]> {
  const themes = await getThemes()
  return Object.keys(themes).map((id: string): string => themes[id].slug)
}

export function getThematicTagInfo (id: string, themes: ThemeObjectType): ThematicTagInfoType {
  const theme = themes[id]
  return {
    id: theme.id,
    title: theme.title,
    logLine: isEmpty(theme.logLine) ? `Listen to audiobooks with ${theme.title} themes` : theme.logLine,
    slug: `/themes/${theme.slug}`,
    description: isEmpty(theme.description) ? '' : theme.description,
    imageUrl: null
  }
}

export function getAudioBookThematicTags (audioBookId: string, library: DipseaContentType): ThematicTagInfoType[] {
  if (!audioBookId) return []
  if (!library.series[audioBookId]) return []
  const themes = library?.series?.[audioBookId]?.themes || {}

  return (Object.keys(themes)).sort((a: string, b: string): number => themes[a] - themes[b]).map((key: string): ThematicTagInfoType => getThematicTagInfo(key, library.themes))
}
export async function getAllThematicTags (): Promise<ThematicTagInfoType[]> {
  const library = await getLibrary()
  const themes = await getThemes()
  return Object.keys(themes).map((id: string): ThematicTagInfoType => getThematicTagInfo(id, library.themes))
}

export function getPageProps (slug: string, library: DipseaContentType): ThematicTagInfoType {
  const id = slug.split('/').pop()
  const theme = Object.keys(library.themes).filter((key: string): boolean => library.themes[key].slug === id)
  return getThematicTagInfo(theme[0], library.themes)
}

export async function getUserQuotes (slug: string): Promise<ReviewType[]> {
  return [
    {
      audioUrl: '',
      key: 'ELLIE',
      quote: `Dipsea is unparalleled when it comes to the production value of their audiobooks and their focus on storytelling, and I love that they have multiple voice actors to play each part!`,
      name: `ELLIE`
    },
    {
      audioUrl: '',
      key: 'ANISHA',
      quote: `Really refreshing take on romance. I love the realism of the stories. Great storytelling, voice acting, and sound design. Well done!`,
      name: `ANISHA`
    },
    {
      audioUrl: '',
      key: 'RACHEL',
      quote: `The narrators are outstanding! The accents are outstanding! The heat factor is volcanic—just off the charts.`,
      name: `RACHEL`
    },
    {
      audioUrl: '',
      key: 'MAYA',
      quote: `This app has so many story genres, whether you’re into fantasy, cowboys, or just sweet and flirty. Definitely a lot of good storytelling by talented writers and voice actors!`,
      name: `MAYA`
    },
    {
      audioUrl: '',
      key: 'JAHNAVI',
      quote: `I love how varied and queer-friendly this app is. The characters feel like real, interesting people and that’s what makes them so compelling.`,
      name: `JAHNAVI`
    },
    {
      audioUrl: '',
      key: 'CAYLEE',
      quote: `Smart, subtle story details that let you know these stories were written by women. Great build-ups and story arcs. Plus the app is well-designed and easy to navigate. Highly recommend!`,
      name: `CAYLEE`
    },
    {
      audioUrl: '',
      key: 'LEAH',
      quote: `I love this app so much. It’s inclusive, with many options for different genders, orientations, scenarios, and interests. Honestly their audiobooks are fun and refreshing and it’s firmly one of the best apps out there for femme audiences.`,
      name: `LEAH`
    }
  ]
}

export function filterAudioBooksByGenre (themeId: string, library: DipseaContentType): string[] {
  let books = []
  Object.keys(library.themes).forEach((key: string): void => {
    if (key === themeId) {
      books = library.themes[key]?.audioBooks || []
    }
  })
  return books
}

export type ThematicTagRowType = {
  id: string,
  title: string,
  description: string | null, // max 450
  logLine: string | null, // max 150 characters
  slug: string,
  audioBooks: SeriesInfoPropsType[],
  popularity: number,
  imageUrl: string | null
}

export function buildSubThemes (mainThemeId: string, library: DipseaContentType): {[themeId: string]: string[]} {
  const audioBookIds = filterAudioBooksByGenre(mainThemeId, library)
  const subThemes = {}
  audioBookIds.forEach((id: string): void => {
    const themes = Object.keys(library.series[id].themes)
    themes.forEach((subTheme: string): void => {
      if (!subThemes[subTheme]) {
        subThemes[subTheme] = []
      }
      subThemes[subTheme].push(id)
    })
  })
  return subThemes
}

export function getThematicTagProps (id: string, library: DipseaContentType, popularity: PopularityBySeriesType[]): ThematicTagRowType {
  const popularityMap = reducePopularity([], popularity)
  const theme = getThematicTagInfo(id, library.themes)
  const subThemes = buildSubThemes(id, library)
  const audioBooks = subThemes[id].map((seriesId: string): SeriesInfoPropsType => {
    const book = convertSeriesToSeriesInfoProps({ ...library.series[seriesId], id: seriesId }, library, popularityMap[seriesId])
    book.tags = []
    return book
  })

  return {
    id: theme.id,
    title: theme.title,
    description: theme.description,
    logLine: theme.logLine,
    slug: theme.slug,
    audioBooks: audioBooks.slice(0, 15),
    popularity: audioBooks.reduce((acc: number, audioBook: SeriesInfoPropsType): number => acc + (audioBook.popularity || 0), 0),
    imageUrl: null
  }
}
export async function buildSubThemeRows (mainThemeId: string): Promise<ThematicTagRowType[]> {
  const [library, popularity] = await Promise.all([getLibrary(), getPopularSeries()])
  const subThemes = buildSubThemes(mainThemeId, library)
  return Object.keys(subThemes).map((themeId: string): ThematicTagRowType => {
    const themeRow = getThematicTagProps(themeId, library, popularity)
    themeRow.title = addRomanceToTitle(themeRow.title)
    themeRow.audioBooks = themeRow.audioBooks.filter((audioBook: SeriesInfoPropsType): boolean => (audioBook.thematicTags || []).filter((tag: ThematicTagInfoType): boolean => tag.id === mainThemeId).length >= 1)
    return themeRow
  })
}

export type ThematicTagPagePropsType = {
  ...ThematicTagRowType,
  carouselTracks: CarouselType[],
  subThemeRows: DeepLinkedCardsCarouselPropsType[],
  spotlightSection: HomePageSpotLightType | null,
  quotesSection: ReviewType[],
  newReleasesRow: HorizontalMediumTrackRowPropsType | null
}

export function convertThematicTagRowToDeepLinkedCardsCarouselProps (row: ThematicTagRowType): DeepLinkedCardsCarouselPropsType {
  const cta = {
    title: 'See All',
    url: convertDeepLink(row.slug)
  }
  return {
    title: row.title,
    displayType: 'deepLinkedCardsCarousel',
    description: row.logLine || null,
    items: row.audioBooks.sort((a: SeriesInfoPropsType, b: SeriesInfoPropsType): number => (b.popularity || 0) - (a.popularity || 0)).map((item: SeriesInfoPropsType): DeepLinkedCardsItemPropsType => {
      return {
        title: item.title,
        slug: convertDeepLink(item.slug),
        imageUrl: item.images.img500,
        badgeText: null,
        description: item.description,
        description2: `${item.episodeInfo} | ${(item.popularity || 0).toLocaleString()} plays`,
        // $FlowFixMe
        tags: item.thematicTags || []
      }
    }),
    cta
  }
}

function convertThemeIntoTrackProps (theme: ThematicTagRowType, library: DipseaContentType, popularityObj: PopularityType = {}): CarouselType[] {
  // $FlowFixMe
  return theme.audioBooks.map((series: SeriesInfoPropsType): CarouselType | null => {
    const track = getFirstTrack(library.series[series.id], library, popularityObj)
    if (!track) return null
    return {
      id: series.id,
      title: series.title,
      description: series.episodeInfo,
      imageUrl: track?.images?.img500 || '',
      slug: series.slug,
      popularity: series.popularity || 0,
      track
    }
  }).filter((track: TrackPropsType | null): boolean => track !== null).sort((a: CarouselType, b: CarouselType): number => {
    return (b.popularity || 0) - (a.popularity || 0)
  })
}

function isAudioBookInTheme (audioBookId: string, themeId: string, library: DipseaContentType): boolean {
  return !!library?.series?.[audioBookId]?.themes?.[themeId]
}
export function getNewReleasesRow (mainThemeId: string, library: DipseaContentType): HorizontalMediumTrackRowPropsType | null {
  const tracks = Object.keys(library.tracks || {})
  const theme = library.themes[mainThemeId]
  const trackIds = tracks.filter((trackId: string): boolean => {
    const track = library.tracks?.[trackId]
    const series = Object.keys(track.serial_ids || {})
    return !!track.publishedAt &&
      track.publishedAt > Date.now() - 60 * 24 * 60 * 60 * 1000 &&
      track.publishedAt <= Date.now() &&
      series.length > 0 &&
      series.some((seriesId: string): boolean => isAudioBookInTheme(seriesId, mainThemeId, library))
  }).sort((a: string, b: string): number => library.tracks[b].publishedAt - library.tracks[a].publishedAt)
  if (trackIds.length < 5) return null
  return {
    title: `New Releases in ${theme.title}`,
    displayType: 'horizontalMediumTrackRow',
    description: `Listen to the latest chapters in ${theme.title}`,
    items: trackIds.map((id: string): TrackPropsType => convertTrackToProps(library.tracks[id], library)),
    cta: null
  }
}

export async function getTheThematicTrackProps (slug: string): Promise<CarouselType[]> {
  const [
    library,
    popularity,
    seriesPopularity
  ] = await Promise.all([
    getLibrary(),
    getPopularTacks(),
    getPopularSeries()
  ])
  const tagInfo = getPageProps(slug, library)
  const subThemeRows = await buildSubThemeRows(tagInfo.id)
  const themeRow: ThematicTagRowType = subThemeRows.filter((item: ThematicTagRowType): boolean => item.id === tagInfo.id)[0]
  const popularityMap = reducePopularity(popularity, seriesPopularity)
  return convertThemeIntoTrackProps(themeRow, library, popularityMap)
}
const dontShowAsSubTheme = ['full-cast', 'audio-drama']

export function filterSubGenres (tagInfo: ThematicTagInfoType, subThemeRows: ThematicTagRowType[]): ThematicTagRowType[] {
  // How many themes have less than 2 books?
  const filteredThemes = subThemeRows.filter((item: ThematicTagRowType): boolean => item.audioBooks.length > 2 && !dontShowAsSubTheme.includes(item.id))
  if (filteredThemes.length > 1) {
    return filteredThemes.filter((item: ThematicTagRowType): boolean => ((item.id !== tagInfo.id)))
  } else {
    return subThemeRows.filter((item: ThematicTagRowType): boolean => ((item.id === tagInfo.id)))
  }
}
export async function getTheThematicTagPageProps (slug: string): Promise<ThematicTagPagePropsType> {
  const [
    quotesSection,
    library,
    popularity,
    seriesPopularity
  ] = await Promise.all([
    getUserQuotes(slug),
    getLibrary(),
    getPopularTacks(),
    getPopularSeries()
  ])
  const tagInfo = getPageProps(slug, library)
  const spotlightSection = await fetchBlogArticlesByTheme(tagInfo.id)
  const theme = getThematicTagProps(tagInfo.id, library, seriesPopularity)
  theme.title = addRomanceToTitle(theme.title)
  const subThemeRows = await buildSubThemeRows(tagInfo.id)
  const themeRow: ThematicTagRowType = subThemeRows.filter((item: ThematicTagRowType): boolean => item.id === tagInfo.id)[0]
  const popularityMap = reducePopularity(popularity, seriesPopularity)
  const carouselTracks = convertThemeIntoTrackProps(themeRow, library, popularityMap)

  return {
    ...theme,
    carouselTracks,
    subThemeRows: filterSubGenres(tagInfo, subThemeRows).map((item: ThematicTagRowType): DeepLinkedCardsCarouselPropsType => convertThematicTagRowToDeepLinkedCardsCarouselProps(item)),
    spotlightSection,
    quotesSection,
    newReleasesRow: getNewReleasesRow(tagInfo.id, library)
  }
}

export async function generateSubThemeRowsForSeries (seriesId: string): Promise<DeepLinkedCardsCarouselPropsType[]> {
  const [
    library,
    seriesPopularity
  ] = await Promise.all([
    getLibrary(),
    getPopularSeries()
  ])

  const themes = Object.keys(library.series[seriesId]?.themes || {}).sort((a: string, b: string): number => library.series[seriesId].themes[a] - library.series[seriesId].themes[b])
  return themes
    .map((theme: string): ThematicTagRowType => {
      const themeRow = getThematicTagProps(theme, library, seriesPopularity)
      themeRow.title = addRomanceToTitle(themeRow.title)
      return themeRow
    })
    .filter((theme: ThematicTagRowType): boolean => theme.audioBooks.length > 2 && !dontShowAsSubTheme.includes(theme.id))
    .map((theme: ThematicTagRowType): ThematicTagRowType => {
      theme.title = `More in ${theme.title}`
      theme.audioBooks = theme.audioBooks.filter((audioBook: SeriesInfoPropsType): boolean => audioBook.id !== seriesId)
      return theme
    })
    .map((item: ThematicTagRowType): DeepLinkedCardsCarouselPropsType => convertThematicTagRowToDeepLinkedCardsCarouselProps(item))
}

export function addRomanceToTitle (title: string): string {
  if (['Full Cast', 'British Accent', 'Scottish Accent', 'Audio Drama', 'Black Voices', 'Overheard', 'Wellness'].includes(title)) return title
  return title.toLowerCase().includes('romance') ? title : `${title} Romance`
}
