// @flow
import styled from 'styled-components'
import type { ColorsType } from './colors'
import * as React from 'react'
import { colors } from './colors'
import { config } from 'react-awesome-styled-grid'

type FormPropsType = {
  padding?: string,
  margin?: string,
  width?: string,
  backgroundColor: ColorsType,
  styles?: string
}
export const Form = styled.form`
  padding: ${(props: FormPropsType): string => props.padding || '0px'};
  margin: ${(props: FormPropsType): string => props.margin || '0px'};
  width: ${(props: FormPropsType): string => props.width || '100%'};
  background-color: ${(props: FormPropsType): string => props.backgroundColor || 'transparent'};
  ${(props: FormPropsType): string => props.styles || ''};
`

export const AuthFormSectionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const CheckBoxContainer = styled.div`
  margin: 5px 0 22px 0; 
  p {
    margin:0;
    padding:0;
    height: 18px;
    display:flex;
    align-items:center;
  }
  span {
    line-height: 24px;
  }
  .control {
    cursor: pointer;
    display: block;
    font-family: arial;
    font-size: 16px;
    position: relative;
    padding: 4px 0 0 26px;
    margin: 0 0 5px;
  }
  .control input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .control_indicator {
    position: relative;
    background: ${(props: any): string => props.error ? colors.white : colors.transparent};
    border: 1px solid ${(props: any): string => props.theme === 'white' ? colors.white : colors.sand};
    border-radius: 3px;
    height: 16px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 16px;
  }
  .error_control_indicator {
    position: absolute;
    background: ${colors.transparent};
    border: 1px solid ${colors.tintOrange};
    border-radius: 3px;
    height: 16px;
    left: -1px;
    position: absolute;
    top: -1px;
    width: 16px;
  }
  .transparentbg + .control_indicator {
    background: transparent !important;
  }
  .control:hover input ~ .control_indicator,
  .control input:focus ~ .control_indicator {
    background: ${colors.transparent};
    box-shadow: 0 0 0 1pt ${(props: any): string => props.theme === 'white' ? colors.white : 'none'};
  }
  .control input:checked ~ .control_indicator {
    background: ${(props: any): string => props.theme === 'white' ? colors.orange : colors.sand};
  }
  .control:hover input:not([disabled]):checked ~ .control_indicator,
  .control input:checked:focus ~ .control_indicator {
      background: ${(props: any): string => props.theme === 'white' ? colors.orange : colors.sand};
  }
  .control input:disabled ~ .control_indicator {
    background: ${colors.transparent};
    opacity: 0.6;
    pointer-events: none;
  }
  .control_indicator:after {
    box-sizing: unset;
    content: '';
    display: none;
    position: absolute;
  }
  .control input:checked ~ .control_indicator:after {
    display: block;
  }
  .control-checkbox .control_indicator:after {
    border: 1px solid ${(props: any): string => props.theme === 'white' ? colors.white : colors.midnightBlue};;
    border-width: 0 2px 2px 0;
    height: 9px;
    left: 5px;
    transform: rotate(45deg);
    top: 1px;
    width: 4px;
  }
  .control-checkbox input:disabled ~ .control_indicator:after {
    border-color: ${(props: any): string => props.theme === 'white' ? colors.white : colors.sand};;
  }
  .control-checkbox .control_indicator::before {
    content: '';
    border-radius: 3rem;
    display: block;
    height: 4.5rem;
    left: 0;
    margin: -1.3rem 0 0 -1.3rem;
    opacity: 0.6;
    position: absolute;
    top: 0;
    transform: scale(0);
    width: 4.5rem;
    z-index: 99999;
  }
`

export const CheckBox = (props: any): any => (
  <CheckBoxContainer theme={props.theme} error={props.error}>
    <label className="control control-checkbox">
      <span>{props.text}</span>
      <input
        onKeyDown={(event: any): void => {
          if (event.keyCode === 13) {
            props.onChange()
          }
        }}
        tabIndex="0"
        type="checkbox" className={props.className} checked={props.checked} onChange={props.onChange}/>
      <div className={'control_indicator'}>{props.error && <div className={'error_control_indicator'}/>}</div>
    </label>
    {props.errorContainer && (
      props.errorContainer
    )}
  </CheckBoxContainer>
)

export const ErrorMessage = styled.div`
  color: ${(props: any): any => (props.showError ? colors.tintOrange : colors.sand)};
  font-family: 'Good Sans Bold';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  padding: 0px;
  text-align: left;

  ${(props: any): any => props.styles};
`
export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: ${(props: any): any => props.minHeight || '20px'};
  padding: 0 0 2px;
  ${(props: any): any => props.styles};
`
export const ErrorContainer = styled.div((props: any): any => ({
  marginTop: '-5px',
  minHeight: props.minHeight ? props.minHeight : '25px',
  paddingTop: props.paddingTop ? props.paddingTop : 0,
  paddingBottom: '5px',
  paddingLeft: '12px',
  height: '100%'
}))

export const FormSmallText = styled.p`
  color: ${(props: any): any => (props.color || colors.sand)};
  font-family: 'Good Sans Regular';
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: 0;

  margin: 4px 0 0 .2em;
  padding: 0;
  text-align: ${(props: any): any => props.textAlign || 'right'};

  ${(props: any): string => config(props).media.lg`
    font-size: 14px;
    line-height: 21px;
  `}
`
export const StyledInput = styled.input`
  border: 1px solid ${(props: any): any => props.borderColor || colors.sand} !important;
  color: ${(props: any): any => props.color || colors.sand} !important;
  display: flex;
  flex: 1;
  height: ${(props: any): any => props.height || '40px'};
  text-indent: 15px;
  background: transparent !important;
  font-family: 'Good Sans Regular';
  font-size: ${(props: any): any => props.fontSize || '16px'};
  line-height: ${(props: any): any => props.height || '40px'};
  border-radius: 7px;
  width: 100%;
  padding: 0px;
  outline: none;

  /* additional style props are inserted here: */
  ${(props: any): any => props.styles};

  :focus {
    -webkit-box-shadow: 0 0 0 30px ${colors.white15} inset !important;
    box-shadow:  0 0 0 30px ${colors.white15} inset !important;
    background-color: ${colors.white15} !important;
    color: ${(props: any): any => props.color || colors.sand} !important;
  }
  /* Override Placeholder Pseudo-elements */
  ::placeholder {
    color: ${(props: any): any => props.placeholderColor || props.color || colors.sand} !important;
  }
  ::-webkit-input-placeholder {
    color: ${(props: any): any => props.placeholderColor || props.color || colors.sand} !important;
  }
  ::-moz-placeholder {
    opacity: 1;
    color: ${(props: any): any => props.placeholderColor || props.color || colors.sand} !important;
  }
  :-ms-placeholder {
    opacity: 1;
    color: ${(props: any): any => props.placeholderColor || props.color || colors.sand} !important;
  }

  /* Override autofill Pseudo-classes */
  :autofill {
    background-color: ${colors.white15} !important;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: ${(props: any): any => props.color || colors.sand} !important;
    color: ${(props: any): any => props.color || colors.sand} !important;
    background-clip: content-box !important;
    background-color: ${colors.white15} !important;
    transition: background-color 80000s !important;
  }

  ${(props: { isError: boolean }): ?string => {
    if (props.isError) {
      return `
        background: ${colors.white} !important;
        outline: 1px solid ${colors.red} !important;
        outline-offset: -4px !important;
        color: ${colors.red} !important;

        ::placeholder {
          font-weight: normal !important;
          color: ${colors.red} !important;
        }
        ::-webkit-input-placeholder {
          color:${colors.red} !important;
        }
        ::-moz-placeholder {
          opacity: 1;
          color: ${colors.red} !important;
        }
        :-ms-placeholder {
          opacity: 1;
          color: ${colors.red} !important;
        }

        &:focus {
          outline: unset !important;
        }
      `
    }
  }}
`
export const FormGroup = styled.div`
  margin: 0 0 10px;
  padding: 0;
`

export const ErrorBodyText = styled.p`
  color: ${(props: any): any => (props.showError ? props.color || colors.red : 'transparent')};
  font-family: 'Good Sans Regular';
  font-size: 11px;
  font-style: normal;
  font-weight: bold;
  line-height: 12px;
  padding: 0;
  margin:0;
  text-align: left;
`

export const CardNumberContainer = styled.div`
  div.StripeElement {
    font-family: 'Good Sans Regular, Roboto, Open Sans, Segoe UI, sans-serif';
    border: 1px solid white;
    border-radius: 7px;
    margin: 0 0 10px;
    padding: 0 0 0 15px;
    overflow: hidden;
    color: ${colors.white} !important;
    background-color: ${colors.transparent} !important;
  }

  div.StripeElement--webkit-autofill {
    background-color: ${colors.white15} !important;
    color: ${colors.white} !important;
    opacity: 1  !important;
  }
  div.StripeElement--focus {
    background-color: ${colors.white15} !important;
  }
  div.StripeElement--complete {
    background-color: ${colors.transparent} !important;
  }
  div.StripeElement--invalid {
    color: ${colors.red} !important;
    background: ${colors.white} !important;
    background-color: ${colors.white} !important;
    outline: 1px solid ${colors.red} !important;
    outline-offset: -4px!important;
  
    ::placeholder {
      font-weight: normal !important;
      color: ${colors.red} !important;
    }
    ::-webkit-input-placeholder {
      color:${colors.red} !important;
    }
    ::-moz-placeholder {
      opacity: 1;
      color: ${colors.red} !important;
    }
    :-ms-placeholder {
      opacity: 1;
      color: ${colors.red} !important;
    }
  }
`

export const CardBottomRowItem = styled(CardNumberContainer)`
  margin: ${(props: any): any => props.margin || '0 0 15px'};
  width: 100%;
`

export const ModalHeader = styled.h1`
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: ${colors.white};
  text-align: center;
  margin: 0 0 20px;
  width: 100%;
  ${(props: any): string => config(props).media.md`
    font-size: 40px;
    line-height: 44px;
  `}
`
export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: any): string => config(props).media.md`
    margin: 64px;
    width: 100%;
  `}
`
export const ModalDescriptionWrapper = styled.div`
  margin: 0 0 48px;
  ${(props: any): string => config(props).media.md`
    margin: 0 0 56px;
  `}
`
export const ModalErrDescriptionWrapper = styled.div`
  margin: 0 0 20px;
  ${(props: any): string => config(props).media.md`
    margin: 0 0 32px;
  `}
`
export const ModalDescription = styled.p`
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.white};
  padding:0 30px;
  margin: 0;
`
export const ButtonSpacer = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props: any): string => config(props).media.md`
    margin-bottom: 32px;
  `}
`
export const LoadingImg = styled.img`
  width: 32px;
  height: 32px;
  ${(props: any): string => config(props).media.md`
    width: 47.5px;
    height: 47.5px;
  `}
  
`
export const LoadingImgWrapper = styled.div`
  margin-bottom: 24px;
  ${(props: any): string => config(props).media.md`
    margin-bottom: 32px;
  `}
`

export const SubscriptionManagementContainer = styled.div`
  margin-top: 32px;
  ${(props: any): string => config(props).media.md`
    margin-top: 0;
  `}
`
export const SubscriptionManagementHeader = styled.h1`
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  text-align:center;
  margin: 0 0 60px;
  padding: 0;
`
export const SubscriptionTextWrapper = styled.div`
  margin-bottom: 40px;
  ${(props: any): string => config(props).media.md`
    margin-bottom: 56px;
  `}
`
export const SubscriptionTextWrapperSplit = styled.div`
  margin-bottom: 30px;
  ${(props: any): string => config(props).media.md`
    align-items: baseline;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
  `}
`
export const ExternalLink = styled.a`
  cursor: pointer;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  align-items: center;
  opacity: 0.5;
  text-decoration: none;
  > * {
    text-align: right;
  }
`
export const TextA = styled.p`
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.white};
  margin: 0;
  padding: 0 0 16px;
  ${(props: any): string => config(props).media.md`
    font-size: 28px;
    line-height: 32px;
    padding: 0 0 20px;
  `}
`
export const TextB = styled.p`
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${(props: any): any => props.color || colors.white};
  margin: 0;
  padding: 0 0 12px;
  display: flex;
  align-items: center;
  ${(props: any): string => config(props).media.md`
    font-size: 24px;
    line-height: 28px;
  `}
`
export const TextC = styled.p`
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.sand60};
  margin: 0;
  padding: 0 0 12px;
  ${(props: any): string => config(props).media.md`
    font-size: 24px;
    line-height: 28px;
    padding: 0 0 0px;
  `}
`

export const TextD = styled.p`
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.secondaryBlue};
  margin: 0;
  padding: 0 0 4px;
  :hover {
    color: ${colors.white};
  }
  ${(props: any): string => config(props).media.md`
    font-size: 20px;
    line-height: 24px;
    padding: 0 0 0px;
  `}
`

export const ButtonWrapper = styled.div`
  margin-top: 10px;
  ${(props: any): string => config(props).media.md`
    margin-top: 20px;
  `}
`

export const TrialSpan = styled.span`
  background: ${colors.white};
  border-radius: 4px;
  margin-left: 16px;
  padding: 2px 6px;
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: black;
`
export const MaxWidthDiv = styled.div`
  max-width: 335px;
`

export const WarningTextP = styled.p`
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: normal;
  color: ${colors.peach};
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  padding: 0 0 12px;
  ${(props: any): string => config(props).media.md`
    font-size: 24px;
    line-height: 28px;
    padding: 0;
  `}
`
export const GifDiv = styled.div`
  align-self: stretch;
`
export const PlainAnchor = styled.a`
  text-decoration: none;
`
export const CardNumberContainer2 = styled.div`
  div.StripeElement--focus {
    background-color: ${colors.white25} !important;
  }
  div.StripeElement {
    border: 1px solid ${colors.white70};
    padding: 14px 0 0 20px;
    height: 30px;
    margin: 0 0 20px;
    border-radius: 7px;
    &.StripeElement--webkit-autofill {
      background-color: ${colors.white25} !important;
    }
  }
  ::placeholder {
    color: rgba(246, 243, 240, 0.5);
  }
`
export const CardBottomRowItem2 = styled.div`
  width: 100%;
  div.StripeElement--focus {
    background-color: ${colors.white25} !important;
  }
  div.StripeElement {
    border: 1px solid ${colors.white70};
    padding: 14px 0 0 20px;
    height: 30px;
    margin: ${(props: any): any => props.margin || '0 0 15px'};
    border-radius: 7px;
    &.StripeElement--webkit-autofill {
      background-color: ${colors.white25} !important;
    }
  }
  ::placeholder {
    color: rgba(246, 243, 240, 0.5);
  }
`
