// @flow
import {
  createRecordAsync,
  fetchOnAsync,
  fetchOnceAsync,
  onRecordChange,
  setRecordAsync,
  turnFetchOff,
  updateRecordAsync
} from '../services/firebaseDatabase'
import type {
  OnRecordChangeType,
  SubscriptionLengthType,
  StripePurchasesRecordType,
  StripePurchaseType,
  StripeUpdatePaymentMethodType,
  TokenType,
  UserStripeUpgradeType
} from '../flowTypes'
import { isDev, isStaging } from '../env'
import { dateNowSeconds } from '../helpers/dateHelper'
import { SUBSCRIPTION_TYPES } from '../helpers/promos'

export type PromoCodeType = {
  promo: string,
  userEnteredPromo: string,
  userId: string
}

export function savePromoCodeOnUserAsync (attrs: PromoCodeType): Promise<void> {
  return setRecordAsync(`/users/${attrs.userId}/usedPromoCodes/${attrs.userEnteredPromo}`, {
    promo: attrs.promo,
    timestamp: Date.now()
  })
}

export function getPlan (type: SubscriptionLengthType): string {
  if (isDev || isStaging()) {
    return {
      // old monthly: 'plan_EbsFkt4wEgdvNG',
      monthly: 'price_1IkuBXFX4IH6P2zBQN745hfc',
      // old annual: 'plan_EbsFX0ImRzFTis'
      annual: 'price_1N88tjFX4IH6P2zB4Uvi3lTs',
      // old six_months: 'price_1MTrjUFX4IH6P2zBg345UOvq'
      six_months: 'price_1N8Og3FX4IH6P2zBjNqHOQHp'
    }[type]
  } else {
    return {
      // old monthly: 'plan_ETWZdaqXCkOL68',
      monthly: 'price_1IljBvFX4IH6P2zB8ijh6POh',
      // old annual: 'plan_Eb2TGp90fYW6NR',
      annual: 'price_1N88ujFX4IH6P2zBQakz08hD',
      // old six_months: 'price_1MTrfgFX4IH6P2zBItQYaw79'
      six_months: 'price_1N8Oh1FX4IH6P2zBmLJ47w4i'
    }[type]
  }
}

export function getTrialRewardType (duration?: number): string {
  switch (duration) {
    case 604800:
      return 'One Week Free'
    case 1209600:
      return 'Two Weeks Free'
    case 2592000:
      return 'One Month Free'
    default:
      return duration ? 'One Month Free' : ''
  }
}

export type SubscribeType = {
  paymentSource: TokenType,
  userId: string,
  email: string,
  plan?: SubscriptionLengthType,
  promo?: string,
  userEnteredPromo?: string,
  referId?: string,
  userExpiring?: boolean
}

export async function subscribe (attrs: SubscribeType): Promise<string> {
  if (!attrs.plan) {
    throw new Error('Please select a valid subscription type')
  }

  const stripeObj: any = {
    email: attrs.email,
    paymentSource: attrs.paymentSource,
    plan: getPlan(attrs.plan),
    userId: attrs.userId
  }

  if (attrs.referId) {
    stripeObj.referId = attrs.referId
  }

  if (attrs.promo) {
    const dateNow = dateNowSeconds()
    if (attrs.plan === SUBSCRIPTION_TYPES.ANNUAL) {
      switch (attrs.promo) {
        case 'oneweekfreetrial': {
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          break
        }
        case 'twoweeksfreetrial': {
          // 14 day trial
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 14)
          break
        }
        case 'onemonthfreetrial': {
          // 30 day trial
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 30)
          break
        }
        case 'threemonthsfreetrial': {
          // 90 day trial
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 90)
          break
        }

        // Coupon only - no trial
        case '30percentoffNoTrial': {
          // One time coupon 30% off, renews at full price
          stripeObj.coupon = '30percentoff'
          break
        }
        case 'fiftypercentoffNoTrial': {
          // One time coupon 50% off, renews at full price
          stripeObj.coupon = 'fiftypercentoff'
          break
        }

        // One time coupon AND trial
        case 'twomonthfreetrial+oneyear20dollars1': {
          // 7 day trial, one time coupon of 20$ for one year, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 60)
          stripeObj.coupon = 'oneyear20dollars1'
          break
        }
        case '15percentoff': {
          // 7 day trial, one time coupon 15% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          stripeObj.coupon = '15percentoff'
          break
        }
        case '20percentoff': {
          // 7 day trial, one time coupon 20% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          stripeObj.coupon = '20percentoff'
          break
        }
        case '25percentoff':
        case '25percentoff+oneweekfreetrial': {
          // 7 day trial, one time coupon 25% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          stripeObj.coupon = '25percentoff'
          break
        }
        case '25percentoff+onemonthfreetrial': {
          // 7 day trial, one time coupon 25% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 30)
          stripeObj.coupon = '25percentoff'
          break
        }
        case '30percentoff': {
          // 7 day trial, one time coupon 30% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          stripeObj.coupon = '30percentoff'
          break
        }
        case 'fiftypercentoff': {
          // 7 day trial, one time coupon 50% off, renews at full price
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          stripeObj.coupon = 'fiftypercentoff'
          break
        }

        // Forever coupon, no trial
        case '4799-price': {
          stripeObj.coupon = '4799-price'
          break
        }
        // Forever coupon and trial
        case '4799-price-7-day-trial': {
          // 7 day trial, Legacy Annual Price - $12.00 off forever
          stripeObj.coupon = '4799-price'
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          break
        }
        case '50percentoffforever+oneweekfreetrial': {
          // 7 day trial, 50% off forever
          stripeObj.coupon = '50percentoff'
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
          break
        }
        case '50percentoffforever+onemonthfreetrial': {
          // 30 day trial, 50% off forever
          stripeObj.coupon = '50percentoff'
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 30)
          break
        }

        default: {
          throw new Error('annual, promo code not found')
        }
      }
    } else if (attrs.plan === SUBSCRIPTION_TYPES.MONTHLY) {
      switch (attrs.promo) {
        case 'onedollarpermonthforthreemonths1': {
          // $1/month for three months, renews at full price
          stripeObj.coupon = 'onedollarpermonthforthreemonths1'
          break
        }
        case 'onedollaramonthfortwelvemonths1': {
          // $1/month for 12 Months, renews at full price
          stripeObj.coupon = 'onedollaramonthfortwelvemonths1'
          break
        }
        case 'sixdollarspermonthforsixmonths': {
          // $6 off for 6 Months ($6.99), renews at full price
          stripeObj.coupon = 'sixdollarspermonthforsixmonths'
          break
        }
        case 'twomonthsfreetrial': {
          // 60 day trial
          stripeObj.trial_end = dateNow + (60 * 60 * 24 * 60)
          break
        }
        default: {
          throw new Error('monthly, promo code not found')
        }
      }
    }
    // else if (attrs.plan === SUBSCRIPTION_TYPES.SIX_MONTHS) {
    //   switch (attrs.promo) {
    //     case 'fiftypercentoff': {
    //       // one time coupon 50% off, renews at full price
    //       stripeObj.coupon = 'fiftypercentoff'
    //       break
    //     }
    //     default: {
    //       throw new Error('6-months, promo code not found')
    //     }
    //   }
    // }
  } else {
    if (attrs.plan === 'annual' && !attrs.userExpiring) {
      // automatically apply 7 day free trial
      const dateNow = dateNowSeconds()
      stripeObj.trial_end = dateNow + (60 * 60 * 24 * 7)
    }
  }

  const purchaseKey: string = await createRecordAsync('/stripePurchases', stripeObj)
  return new Promise((resolve: (code: string) => void, reject: (error: {}) => void): void => {
    const refConfirmed = `/stripePurchases/${purchaseKey}/confirmed`
    const refError = `/stripePurchases/${purchaseKey}/error`

    fetchOnAsync(refConfirmed, (snapshot: any): void => {
      const code = snapshot.val()
      if (code) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        if (attrs.promo && attrs.userEnteredPromo) {
          savePromoCodeOnUserAsync({
            promo: attrs.promo,
            userEnteredPromo: attrs.userEnteredPromo,
            userId: attrs.userId
          })
        }
        resolve(code)
      }
    })

    fetchOnAsync(refError, (snapshot: any): void => {
      const error = snapshot.val()
      if (error) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        reject(error)
      }
    })
  })
}

export async function subscribe2 (attrs: SubscribeType): Promise<string> {
  if (!attrs.plan) {
    throw new Error('Please select a valid subscription type')
  }

  const stripeObj: any = {
    email: attrs.email,
    paymentSource: attrs.paymentSource,
    subscription_length: attrs.plan,
    userId: attrs.userId
  }

  if (attrs.userEnteredPromo) {
    stripeObj.promoCode = attrs.userEnteredPromo
  }

  if (attrs.referId) {
    stripeObj.referId = attrs.referId
  }

  const purchaseKey: string = await createRecordAsync('/stripePurchases', stripeObj)

  return new Promise((resolve: (code: string) => void, reject: (error: {}) => void): void => {
    const refConfirmed = `/stripePurchases/${purchaseKey}/confirmed`
    const refError = `/stripePurchases/${purchaseKey}/error`

    fetchOnAsync(refConfirmed, (snapshot: any): void => {
      const code = snapshot.val()
      if (code) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        if (attrs.userEnteredPromo) {
          savePromoCodeOnUserAsync({
            promo: attrs.userEnteredPromo,
            userEnteredPromo: attrs.userEnteredPromo,
            userId: attrs.userId
          })
        }
        resolve(code)
      }
    })

    fetchOnAsync(refError, (snapshot: any): void => {
      const error = snapshot.val()
      if (error) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        reject(error)
      }
    })
  })
}

export async function getStripePurchaseDataAsync (purchaseKey: string): Promise<?StripePurchaseType> {
  const purchaseData: any = await fetchOnceAsync(`/stripePurchases/${purchaseKey}`)
  if ((purchaseData: StripePurchasesRecordType)) {
    return {
      last4: purchaseData.paymentSource.card.last4,
      trialEnd: purchaseData.trial_end,
      card: purchaseData.paymentSource.card,
      lastPaymentSuccessful: purchaseData.lastPaymentSuccessful
    }
  }
  return null
}

export const cancelStripeRenewalAsync = (purchaseKey: string): Promise<any> => {
  return setRecordAsync(`/stripePurchases/${purchaseKey}/cancelledOn`, Date.now())
}

export function monitorStripeUpgradeRenewsAt (userId: string, callback: (renewsAt: number) => void): OnRecordChangeType {
  return onRecordChange(`/users/${userId}/subscription_renews_at`, callback)
}

export function monitorStripeUserUpgrade (userId: string, callback: (upgradeObject: UserStripeUpgradeType) => void): OnRecordChangeType {
  return onRecordChange(`/users/${userId}/upgrade`, callback)
}

export const triggerStripeUpgradeAsync = (purchaseKey: string): Promise<any> => {
  return updateRecordAsync(`/stripePurchases/${purchaseKey}/upgrade`, {
    state: 'requested',
    timestamp: Date.now()
  })
}

export const initializeStripeKeepSubscription = (purchaseKey: string): Promise<any> => {
  return setRecordAsync(`/stripePurchases/${purchaseKey}/keepSubscription`, true)
}

export async function monitorStripeKeepSubscription (purchaseKey: string): Promise<boolean> {
  await initializeStripeKeepSubscription(purchaseKey)
  return new Promise((resolve: (isSuccessful: boolean) => void): void => {
    const refConfirmed = `/stripePurchases/${purchaseKey}/keepSubscriptionSuccess`
    const refError = `/stripePurchases/${purchaseKey}/keepSubscriptionError`

    fetchOnAsync(refConfirmed, (snapshot: any): void => {
      const isTrue = snapshot.val()
      if (isTrue) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(true)
      }
    })

    fetchOnAsync(refError, (snapshot: any): void => {
      const error = snapshot.val()
      if (error) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(false)
      }
    })
  })
}

export const initializeUpdatePaymentMethod = (purchaseKey: string, newPaymentMethod: StripeUpdatePaymentMethodType): Promise<any> => {
  return updateRecordAsync(`/stripePurchases/${purchaseKey}`, {
    updatePaymentMethod: newPaymentMethod,
    updatePaymentMethodSuccess: null,
    updatePaymentMethodError: null
  })
}

export async function monitorUpdatePaymentMethod (purchaseKey: string, newPaymentMethodId: StripeUpdatePaymentMethodType): Promise<any> {
  await initializeUpdatePaymentMethod(purchaseKey, newPaymentMethodId)
  return new Promise((resolve: (isSuccessful: boolean | string) => void): void => {
    const refConfirmed = `/stripePurchases/${purchaseKey}/updatePaymentMethodSuccess`
    const refError = `/stripePurchases/${purchaseKey}/updatePaymentMethodError`

    fetchOnAsync(refConfirmed, (snapshot: any): void => {
      const isTrue = snapshot.val()
      if (isTrue) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(true)
      }
    })

    fetchOnAsync(refError, (snapshot: any): void => {
      const error = snapshot.val()
      if (error) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(error)
      }
    })
  })
}

export const initializeApplyNewCouponSubscription = (purchaseKey: string, couponId: 'fiftypercentoff' | '30percentoff' = 'fiftypercentoff'): Promise<any> => {
  return setRecordAsync(`/stripePurchases/${purchaseKey}/applyNewCoupon`, couponId)
}

export async function monitorApplyNewCouponSubscription (purchaseKey: string, coupon_id: 'fiftypercentoff' | '30percentoff' = 'fiftypercentoff'): Promise<boolean> {
  await initializeApplyNewCouponSubscription(purchaseKey, coupon_id)
  return new Promise((resolve: (isSuccessful: boolean) => void): void => {
    const refConfirmed = `/stripePurchases/${purchaseKey}/applyNewCouponSuccess`
    const refError = `/stripePurchases/${purchaseKey}/applyNewCouponError`

    fetchOnAsync(refConfirmed, (snapshot: any): void => {
      const isTrue = snapshot.val()
      if (isTrue) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(true)
      }
    })

    fetchOnAsync(refError, (snapshot: any): void => {
      const error = snapshot.val()
      if (error) {
        turnFetchOff(refConfirmed)
        turnFetchOff(refError)
        resolve(false)
      }
    })
  })
}
