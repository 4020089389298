// @flow
import type { DipseaContentType, TagType, TrackType } from '../flowTypes'
import type { PopularityByTrackRowType, PopularityBySeriesType } from '../services/dipseaApi'
import {
  getLibrary,
  getPopularSeries,
  getPopularTacks,
  getScoredTracks
} from '../services/dipseaApi'
import { cloneDeep } from 'lodash'
import { getDurationText, sortSlugs } from './helpers'
import type { TagInfoType } from './Tag'
import {
  convertSeriesBySlugToProps, convertSeriesFeatured,
  getEpisodeInformation,
  getSeriesTracks
} from './Series'
import type {
  DeepLinkedCardsCarouselPropsType,
  DeepLinkedCardsItemPropsType,
  HorizontalMediumTrackRowPropsType
} from './Discover'
import { filterTag } from './Tag'
import {
  getAudioBookThematicTags, getPageProps,
  getTheThematicTrackProps
} from './ThematicLandingPage'
import type { ThematicTagInfoType } from './ThematicLandingPage'
import type { SeriesFeaturedType, SeriesPropsType } from './Series'

export type TrackSeriesInfoType = { id: string, slug: string, title: string, episodeInfo: string | null, seriesChaptersInfo: string | null, popularity: number, featured: SeriesFeaturedType | null }

export type TrackPropsType = {
  id: string,
  title: string,
  audioUrl: string,
  description: string,
  trackPreviewUrl: string | null,
  duration: number,
  lengthText: string,
  slug: string,
  series: TrackSeriesInfoType[],
  creator: TagInfoType | null,
  tags: TagInfoType[],
  popularity: number | null,
  publishedAt: number,
  images: {
    ['img100' | 'img500' | 'img800' | 'img1000']: string
  },
  sexIndicatorTime: number | null,
  sexIndicatorText: string | null,
  thematicTags: ThematicTagInfoType[],
  isCharacterExp: boolean
}

export type TrackImagesType = {
  img100: string,
  img500: string,
  img800: string,
  img1000: string
}

export function getCanonicalSlug (track: TrackType): string {
  return sortSlugs(track.slugs)[0]
}

export async function getTrackSlugsAsync (): Promise<string[]> {
  const library = await getLibrary()
  return Object.keys(library?.slugs?.tracks).filter((slug: string): boolean => {
    const key = library?.slugs?.tracks[slug]
    const track = library.tracks?.[key]
    if (!track) {
      return false
    }
    return isValidTrack(track)
  })
}

export async function getTrackBySlugAsync (slug: string): Promise<TrackType> {
  const library = await getLibrary()
  const trackId = library?.slugs?.tracks[slug]
  const track = cloneDeep(library?.tracks[trackId] || {})
  return {
    ...track,
    id: trackId
  }
}

export async function getTrackByIdAsync (trackId: string): Promise<TrackType> {
  const library = await getLibrary()
  const track = cloneDeep(library?.tracks[trackId] || {})
  return {
    ...track,
    id: trackId
  }
}

export async function getMoreLikeTracks (trackId: string): Promise<TrackPropsType[]> {
  const [tracks, library] = await Promise.all([getScoredTracks(), getLibrary()])
  const trackIds = tracks?.[trackId]?.tracks || []
  return trackIds
    .filter((key: string): boolean => isValidTrack(library.tracks[key]))
    .map((trackId: string): TrackPropsType => {
      return convertTrackToProps(library.tracks[trackId], library)
    })
}

export async function getMoreLikeTracksSlugs (): Promise<string[]> {
  const library = await getLibrary()
  const trackSlugs = Object.keys(library?.slugs?.tracks)
  const tracks = await getScoredTracks()
  return trackSlugs.filter((slug: string): boolean => {
    const trackId = library?.slugs?.tracks[slug]
    const track = library.tracks?.[trackId]
    if (!tracks?.[trackId]?.tracks?.length || !track) {
      return false
    }
    if (getCanonicalSlug(track) !== slug) {
      return false
    }
    return !track.deleted && !!track?.publishedAt && !!track?.description && !!track?.publishedAt && !!track?.audio_url && Object.keys(track.tag_ids || {}).length > 0
  })
}

export async function getSeriesInfoFromTrackAsync (track: TrackType): Promise<TrackSeriesInfoType[]> {
  const [library, popular] = await Promise.all([getLibrary(), getPopularSeries()])
  const seriesIds = Object.keys(track.serial_ids || {})
  const popularityMap = reducePopularity([], popular)

  return seriesIds.map((seriesId: string): TrackSeriesInfoType => {
    const series = library.series[seriesId]
    const slug = sortSlugs(series.slugs)[0]
    const episodeNumber = series?.track_ids?.[track.id].index + 1
    const episodeLabel = series.episodesLabel || 'Chapter'
    const episodeInfo = episodeNumber ? `${episodeLabel.slice(0, 2)}. ${episodeNumber} |` : ''
    return {
      id: seriesId,
      slug: `/audiobooks/${slug}`,
      title: series.name,
      episodeInfo,
      seriesChaptersInfo: getEpisodeInformation(series),
      popularity: popularityMap[seriesId] || 0,
      featured: convertSeriesFeatured(series)
    }
  })
}

export function getSeriesInfoFromTrack (track: TrackType, library: DipseaContentType, popularityObj: { [trackOrSeriesId: string]: number } = {}): TrackSeriesInfoType[] {
  const seriesIds = Object.keys(track.serial_ids || {})

  return seriesIds.map((seriesId: string): TrackSeriesInfoType => {
    const series = library.series[seriesId]
    const slug = sortSlugs(series.slugs)[0]
    const episodeNumber = series?.track_ids?.[track.id].index + 1
    const episodeLabel = series.episodesLabel || 'Chapter'
    const episodeInfo = episodeNumber ? `${episodeLabel.slice(0, 2)}. ${episodeNumber} |` : ''
    return {
      id: seriesId,
      slug: `/audiobooks/${slug}`,
      title: series.name,
      episodeInfo: isPillowTalk(track.id, library) ? null : episodeInfo,
      seriesChaptersInfo: getEpisodeInformation(series),
      popularity: popularityObj[seriesId] || 0,
      featured: convertSeriesFeatured(series)
    }
  })
}

export function getFirstSeriesIdFromTrack (track: TrackType): string {
  const seriesIds = Object.keys(track.serial_ids || {}).sort((a: string, b: string): number => track.serial_ids[a] - track.serial_ids[b])
  return seriesIds[0]
}

export async function getTagInfoFromTrackAsync (track: TrackType): Promise<TagInfoType[]> {
  const library = await getLibrary()
  const tagIds = Object.keys(track.tag_ids || {}).filter((tagId: string): boolean => !filterTag(tagId))

  return tagIds.sort((a: string, b: string): number => track.tag_ids[a].index - track.tag_ids[b].index).map((tagId: string): TagInfoType => {
    const tag: TagType = library.tags[tagId]
    const slug = sortSlugs(tag.slugs)[0]
    return {
      id: tagId,
      slug: `/tags/${slug}`,
      title: tag.name,
      imageUrl: tag.image_url || null,
      logLine: null,
      description: null
    }
  })
}

export function getTagInfoFromTrack (track: TrackType, library: DipseaContentType): TagInfoType[] {
  const tagIds = Object.keys(track.tag_ids || {}).filter((tagId: string): boolean => !filterTag(tagId))

  return tagIds.sort((a: string, b: string): number => track.tag_ids[a].index - track.tag_ids[b].index).map((tagId: string): TagInfoType => {
    const tag: TagType = library.tags[tagId]
    const slug = sortSlugs(tag.slugs)[0]
    return {
      id: tagId,
      slug: `/tags/${slug}`,
      title: tag.name,
      imageUrl: tag.image_url || null,
      logLine: null,
      description: null
    }
  })
}

export function convertTrackImages (track: TrackType): TrackImagesType {
  return {
    img100: track['image-100'],
    img500: track['image-500'],
    img800: track['image-800'],
    img1000: track['image-1000']
  }
}

export async function convertTrackToPropsAsync (track: TrackType, popularity: PopularityType): Promise<TrackPropsType> {
  const slug = sortSlugs(track.slugs)[0]
  const [series, tags, library] = await Promise.all([getSeriesInfoFromTrackAsync(track), getTagInfoFromTrackAsync(track), getLibrary()])
  return {
    id: track.id,
    title: track.title,
    audioUrl: track.audio_url,
    images: convertTrackImages(track),
    sexIndicatorTime: getIndicatorTime(track),
    sexIndicatorText: getIndicatorText(track),
    trackPreviewUrl: track?.trackPreview?.previewUrl || null,
    description: track.description,
    duration: track.duration,
    lengthText: getDurationText(track.duration / 60),
    slug: `/chapters/${slug}`,
    series,
    tags,
    creator: null,
    publishedAt: track.publishedAt,
    popularity: popularity[track.id] || 0,
    thematicTags: getAudioBookThematicTags(series?.[0]?.id, library),
    isCharacterExp: isPillowTalkTrackTypeCheck(track)
  }
}

export function getIndicatorTime (track: TrackType): number | null {
  try {
    if (!track.indicatorTime) {
      return null
    }
    return Math.round((1 - track.indicatorTime / track.duration) * 100)
  } catch (e) {
    return null
  }
}

export function getIndicatorText (track: TrackType): string | null {
  try {
    if (!track.indicatorTime) {
      return null
    }
    return getDurationText((track.duration - track.indicatorTime) / 60)
  } catch (e) {
    return null
  }
}
export type PopularityType = { [trackOrSeriesId: string]: number }
export function convertTrackToProps (track: TrackType, library: DipseaContentType, popularityObj: PopularityType = {}): TrackPropsType {
  const slug = sortSlugs(track.slugs)[0]
  const series = getSeriesInfoFromTrack(track, library, popularityObj)
  const tags = getTagInfoFromTrack(track, library)
  const hunkTag = getHunkInfoAsTagProps(track, library)
  const creator = getCreatorInfoAsTagProps(track, library)
  const thematicTags = getAudioBookThematicTags(series?.[0]?.id, library)

  if (hunkTag) {
    tags.push(hunkTag)
  }
  const popularity = popularityObj[track.id] || 0
  return {
    id: track.id,
    sexIndicatorTime: getIndicatorTime(track),
    sexIndicatorText: getIndicatorText(track),
    title: track.title,
    audioUrl: track.audio_url,
    images: convertTrackImages(track),
    trackPreviewUrl: track?.trackPreview?.previewUrl || null,
    description: track.description,
    duration: track.duration,
    lengthText: getDurationText(track.duration / 60),
    slug: `/chapters/${slug}`,
    series,
    creator,
    tags,
    popularity,
    publishedAt: track.publishedAt,
    thematicTags,
    isCharacterExp: isPillowTalk(track.id, library)
  }
}

export function getTrackProps (id: string, library: DipseaContentType): TrackPropsType {
  const track = library.tracks[id]
  return convertTrackToProps(track, library)
}

export function convertTrackBySlugToProps (slug: string, library?: DipseaContentType): TrackPropsType | null {
  if (!library) {
    return null
  }
  const trackId = library?.slugs?.tracks[slug]

  if (!trackId) {
    return null
  }
  const track = cloneDeep(library?.tracks[trackId] || {})

  if (!track) {
    return null
  }
  const clonedTrack = {
    ...track,
    id: trackId
  }

  return convertTrackToProps(clonedTrack, library)
}

export async function convertTrackBySlugToPropsAsync (slug: string): Promise<TrackPropsType> {
  const [track, trackPopularity, seriesPopularity] = await Promise.all([getTrackBySlugAsync(slug), getPopularTacks(), getPopularSeries()])
  const popularityMap = reducePopularity(trackPopularity, seriesPopularity)
  return convertTrackToPropsAsync(track, popularityMap)
}

export async function convertTrackByIdToProps (trackId: string): Promise<TrackPropsType> {
  const [track, trackPopularity, seriesPopularity] = await Promise.all([getTrackByIdAsync(trackId), getPopularTacks(), getPopularSeries()])
  const popularityMap = reducePopularity(trackPopularity, seriesPopularity)
  return convertTrackToPropsAsync(track, popularityMap)
}

export function isPillowTalk (trackId: string, library: DipseaContentType): boolean {
  const pillowTalkId = '-Lzjv_nyDUi2lRy47uXe'
  return !!library.tracks?.[trackId]?.search_tag_ids?.[pillowTalkId]
}

export function isPillowTalkTrackTypeCheck (track: TrackType): boolean {
  const pillowTalkId = '-Lzjv_nyDUi2lRy47uXe'
  return !!track?.search_tag_ids?.[pillowTalkId]
}

export function isPillowTalkPropsCheck (track: TrackPropsType): boolean {
  const pillowTalkId = '-Lzjv_nyDUi2lRy47uXe'
  return track.tags.filter((tag: TagInfoType): boolean => {
    console.log(tag.id === pillowTalkId)
    return tag.id === pillowTalkId
  }).length > 0
}

export function getPlusYouTracks (tracks: TrackPropsType[], library: DipseaContentType): HorizontalMediumTrackRowPropsType | null {
  const tracksArr = tracks.filter((track: TrackPropsType): boolean => isPillowTalk(track.id, library))
  if (!tracksArr.length) {
    return null
  }
  return {
    title: 'Pillowtalk Style',
    displayType: 'horizontalMediumTrackRow',
    description: null,
    items: tracksArr,
    cta: null
  }
}

export function getNextInSeriesTracks (track: TrackPropsType, tracks: TrackPropsType[], library: DipseaContentType): HorizontalMediumTrackRowPropsType | null {
  const trackArr = tracks.filter((trackItem: TrackPropsType): boolean => !isPillowTalk(trackItem.id, library))
  const trackIndex = trackArr.findIndex((trackItem: TrackPropsType): boolean => {
    return trackItem.id === track.id
  })
  const tracksArr = trackArr.slice(trackIndex + 1)
  if (!tracksArr.length) {
    return null
  }
  return {
    title: 'Next Up',
    displayType: 'horizontalMediumTrackRow',
    description: null,
    items: tracksArr,
    cta: null
  }
}

export function getPrevInSeriesTracks (track: TrackPropsType, tracks: TrackPropsType[], library: DipseaContentType): HorizontalMediumTrackRowPropsType | null {
  const trackArr = tracks.filter((trackItem: TrackPropsType): boolean => !isPillowTalk(trackItem.id, library))
  const trackIndex = trackArr.findIndex((trackItem: TrackPropsType): boolean => trackItem.id === track.id)
  let tracksArr = trackArr.slice(0, trackIndex)
  if (!tracksArr.length) {
    return null
  }

  if (isPillowTalk(track.id, library)) {
    tracksArr = trackArr
  }
  return {
    title: 'Previous',
    displayType: 'horizontalMediumTrackRow',
    description: null,
    items: tracksArr,
    cta: null
  }
}

export function getActorTracks (trackId: string, library: DipseaContentType): TrackPropsType[] {
  const track = library.tracks[trackId]
  if (track.actors) {
    const actorId = Object.keys(track.actors)[0]
    const actor = library.actors[actorId]
    const trackIds = Object.keys(actor.trackIds).sort((a: string, b: string): number => actor.trackIds[a] - actor.trackIds[b])
    return trackIds
      .filter((key: string): boolean => isValidTrack(library.tracks[key]))
      .map((trackId: string): TrackPropsType => convertTrackToProps(library.tracks[trackId], library))
  }
  return []
}

export function convertMoreLikeToHorizontalMediumTrackRowProps (tracks: TrackPropsType[], type: 'actor' | 'like', slug: string, actorId?: string | null): HorizontalMediumTrackRowPropsType | null {
  if (!tracks?.length) {
    return null
  }
  return {
    title: type === 'like' ? 'More With Similar Themes' : 'More With This Actor',
    displayType: 'horizontalMediumTrackRow',
    description: null,
    items: tracks,
    cta: {
      title: 'See All',
      url: type === 'like' ? '/chapters/more-like/' + slug : slug + '/more-like-actor/' + (actorId || '')
    }
  }
}

export type TrackDetailPropsType = {
  track: TrackPropsType,
  nextInSeries: HorizontalMediumTrackRowPropsType | null,
  previouslyInSeries: HorizontalMediumTrackRowPropsType | null,
  plusYou: HorizontalMediumTrackRowPropsType | null,
  moreLike: HorizontalMediumTrackRowPropsType | null,
  moreLikeActor: HorizontalMediumTrackRowPropsType | null
}

export async function getTrackDetailPropsAsync (slug: string): Promise<TrackDetailPropsType> {
  const [
    track,
    library,
    popularity,
    seriesPopularity] = await Promise.all([
    getTrackBySlugAsync(slug),
    getLibrary(),
    getPopularTacks(),
    getPopularSeries()
  ])
  const canonicalSlug = getCanonicalSlug(track)
  const moreLike = await getMoreLikeTracks(track.id)
  const trackDetails = convertTrackToProps(track, library)
  const seriesId = getFirstSeriesIdFromTrack(track)
  const series = library.series[seriesId]
  const tracksInSeries = getSeriesTracks(series, library, reducePopularity(popularity, seriesPopularity))
  const moreLikeActor = getActorTracks(track.id, library).slice(0, 20)
  const actorId = Object.keys(track.actors || {})[0]
  const isPillow = isPillowTalk(track.id, library)
  if (isPillow) {
    return {
      track: trackDetails,
      nextInSeries: null,
      previouslyInSeries: getPrevInSeriesTracks(trackDetails, tracksInSeries, library),
      plusYou: getPlusYouTracks(tracksInSeries, library),
      moreLike: convertMoreLikeToHorizontalMediumTrackRowProps(moreLike.slice(0, 20), 'like', canonicalSlug),
      moreLikeActor: convertMoreLikeToHorizontalMediumTrackRowProps(moreLikeActor, 'actor', canonicalSlug, actorId)
    }
  }
  return {
    track: trackDetails,
    nextInSeries: getNextInSeriesTracks(trackDetails, tracksInSeries, library),
    previouslyInSeries: getPrevInSeriesTracks(trackDetails, tracksInSeries, library),
    plusYou: getPlusYouTracks(tracksInSeries, library),
    moreLike: convertMoreLikeToHorizontalMediumTrackRowProps(moreLike.slice(0, 20), 'like', canonicalSlug),
    moreLikeActor: convertMoreLikeToHorizontalMediumTrackRowProps(moreLikeActor, 'actor', canonicalSlug, actorId)
  }
}

export type PopularPropsType = {
  title: string,
  tracks: TrackPropsType[]
}

export async function getPopularPropsAsync (): Promise<PopularPropsType> {
  const [library, popularTracks] = await Promise.all([getLibrary(), getPopularTacks()])
  const trackIds = popularTracks
    .sort((a: PopularityByTrackRowType, b: PopularityByTrackRowType): number => (b.all_time_track_plays || 0) - (a.all_time_track_plays || 0))
    .slice(0, 100).map((track: PopularityByTrackRowType): string => track.track_id)
  const tracks = trackIds
    .filter((key: string): boolean => isValidTrack(library.tracks[key]))
    .map((trackId: string): TrackPropsType => convertTrackToProps(library.tracks[trackId], library))
  return {
    title: 'Popular',
    tracks
  }
}

export async function getNewReleasesTracks (): Promise<HorizontalMediumTrackRowPropsType> {
  const library = await getLibrary()
  const tracks = Object.keys(library.tracks || {}).sort((a: string, b: string): number => {
    const trackA = library.tracks[a]
    const trackB = library.tracks[b]
    return trackB.publishedAt - trackA.publishedAt
  })
    .filter((key: string): boolean => isValidTrack(library.tracks[key]))
    .map((trackId: string): TrackPropsType => convertTrackToProps(library.tracks[trackId], library))
    .slice(0, 10)
  return {
    title: 'New Releases',
    displayType: 'horizontalMediumTrackRow',
    description: null,
    items: tracks,
    cta: {
      title: 'SEE ALL',
      url: '/chapters/new-releases'
    }
  }
}

export function isValidTrack (track: TrackType): boolean {
  return !!track && !track.deleted && !!track?.publishedAt && !!track?.description && track?.publishedAt < Date.now() && !!track?.audio_url && Object.keys(track.tag_ids || {}).length > 0
}

export function reducePopularity (tracks: PopularityByTrackRowType[], series: PopularityBySeriesType[]): PopularityType {
  const popularityMap = tracks.reduce((acc: {
    [key: string]: number
  }, track: PopularityByTrackRowType, index: number): { [key: string]: number } => {
    acc[track.track_id] = track.all_time_track_plays
    return acc
  }, {})
  series.forEach((series: PopularityBySeriesType): void => { popularityMap[series.series_id] = series.all_time_track_plays })
  return popularityMap
}

export async function getAllPopularTracksProps (themeId?: string): Promise<TrackPropsType[]> {
  const [library, popularity, seriesPopularity] = await Promise.all([getLibrary(), getPopularTacks(), getPopularSeries()])

  const popularityMap = reducePopularity(popularity, seriesPopularity)
  const tracks = Object.keys(library.tracks || {})
    .filter((key: string): boolean => isValidTrack(library.tracks[key]))
    .map((trackId: string): TrackPropsType => convertTrackToProps({
      ...library?.tracks[trackId],
      id: trackId
    }, library, popularityMap)).filter((track: TrackPropsType): boolean => {
      if (themeId) {
        let theme = themeId
        if (!library.themes[theme]) {
          theme = getPageProps(themeId, library).id
        }
        const series = track.series?.[0]

        return !!series && library?.series?.[series.id]?.themes?.[theme] !== undefined
      } else {
        return true
      }
    })

  return tracks
    .sort((a: TrackPropsType, b: TrackPropsType): number => (b.popularity || 0) - (a.popularity || 0))
}

export function getHunkInfoAsTagProps (track: TrackType, library: DipseaContentType): TagInfoType | null {
  const cats = Object.keys(track.context_ids || {})
  if (cats.length === 0) {
    return null
  }
  const hunkCats = cats.filter((catId: string): boolean => {
    const cat = library.contexts[catId]
    if (!cat || !cat.subType) {
      return false
    }
    if (cat.subType === 'hunks') {
      return true
    }
    return false
  })
  if (hunkCats.length === 0) {
    return null
  }
  const hunkCat = library.contexts[hunkCats[0]]
  const slug = sortSlugs(hunkCat.slugs)[0]
  return {
    id: hunkCat.id,
    title: hunkCat.display_name,
    imageUrl: hunkCat.hunk_profile_image_url || null,
    slug: `/characters/${slug}`,
    logLine: null,
    description: null
  }
}

export function getCreatorInfoAsTagProps (track: TrackType, library: DipseaContentType): TagInfoType | null {
  const cats = Object.keys(track.context_ids || {})
  if (cats.length === 0) {
    return null
  }
  const creatorCats = cats.filter((catId: string): boolean => {
    const cat = library.contexts[catId]
    if (!cat || !cat.subType) {
      return false
    }
    if (cat.subType === 'creator') {
      return true
    }
    return false
  })
  if (creatorCats.length === 0) {
    return null
  }
  const creatorCat = library.contexts[creatorCats[0]]
  const slug = sortSlugs(creatorCat.slugs)[0]
  return {
    id: creatorCat.id,
    title: creatorCat.display_name,
    imageUrl: creatorCat.image_url || null,
    slug: `/creators/${slug}`,
    logLine: null,
    description: null
  }
}

export type CarouselType = {
  id: string,
  track: TrackPropsType,
  slug: string,
  title: string,
  description: string,
  popularity: number,
  imageUrl: string
}

export function convertTrackPropsToCarouseProps (track: TrackPropsType): CarouselType {
  const description = track.series[0].episodeInfo ? `${track.series[0].episodeInfo} ${track.series[0].title}` : 'Character Experience'
  return {
    id: track.id,
    track,
    slug: track.slug,
    title: track.title,
    description,
    popularity: track.popularity || 0,
    imageUrl: track.images.img1000
  }
}

export async function getCarouselTrackProps (path: string, slug?: string, number?: number): Promise<CarouselType[]> {
  let tracks = []
  if (slug && path.includes('theme')) {
    tracks = await getTheThematicTrackProps(slug)
  }

  if (slug && (path.includes('series') || path.includes('audiobooks'))) {
    const series = await convertSeriesBySlugToProps(slug)
    return series.tracks.map(convertTrackPropsToCarouseProps)
  }

  if (tracks.length > 4) {
    return tracks
  }
  if (slug && path.includes('theme') && tracks.length < 5) {
    tracks = await getAllPopularTracksProps(slug)
    tracks = tracks.sort((a: TrackPropsType, b: TrackPropsType): number => (b.popularity || 0) - (a.popularity || 0))
      .slice(0, number)
  } else {
    tracks = await getAllPopularTracksProps(slug)
    tracks = tracks.filter((track: TrackPropsType): boolean => ['-NtRKLWAHefIvaYehE7F', '-NFk9XBc1yMYnFvhBYOO', '-Nq3SWqMUc8A-SUZdBe-', '-N7IopRxABY4ExIUSyIP', '-Noo9P6iQV4HMnmwwq1K'].includes(track.id))
      .sort((a: TrackPropsType, b: TrackPropsType): number => (b.popularity || 0) - (a.popularity || 0))
      .slice(0, number)
  }

  tracks = tracks.sort((a: TrackPropsType, b: TrackPropsType): number => (b.popularity || 0) - (a.popularity || 0))
  if (number) {
    tracks = tracks.slice(0, number)
  }
  return tracks.map(convertTrackPropsToCarouseProps)
}

export function convertTrackIntoDeepCardCarouselProps (track: TrackPropsType): DeepLinkedCardsItemPropsType {
  return {
    title: track.title,
    slug: track.slug,
    imageUrl: track.images.img500,
    badgeText: 'Character Experience',
    description: track.description,
    description2: track.series[0].episodeInfo,
    tags: track.tags
  }
}

export function generateCharacterExperienceRowForSeries (series: SeriesPropsType, library: DipseaContentType): DeepLinkedCardsCarouselPropsType[] | null {
  const tracks = series.tracks.filter((track: TrackPropsType): boolean => isPillowTalk(track.id, library))
  if (tracks.length === 0) {
    return null
  }
  const hunk = getHunkInfoAsTagProps(library.tracks[tracks[0].id], library)
  if (!hunk && tracks.length === 0) {
    return null
  }
  let title = `Character Experiences`
  let description = `Have spicy one-on-one experiences characters from '${series.title}'`
  let cta = null
  if (hunk) {
    title = `Character Experiences: ${hunk.title}`
    description = `Have spicy one-on-one experiences with ${hunk.title} from the audiobook, '${series.title}'`
    cta = {
      title: 'See All',
      url: hunk.slug
    }
  }

  return [{
    title,
    displayType: 'deepLinkedCardsCarousel',
    description,
    items: tracks.sort((a: TrackPropsType, b: TrackPropsType): number => b.publishedAt - a.publishedAt).map(convertTrackIntoDeepCardCarouselProps),
    cta
  }]
}
