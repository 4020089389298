// @flow
export { version } from './package'

export const isServer: boolean = typeof window === 'undefined'

export const isDev: boolean = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'

export const isTest: boolean = process.env.NODE_ENV === 'test'

export const isLocal: () => boolean = () => {
  const publicRuntimeConfig = envConfig()
  return publicRuntimeConfig.APP_ENV === 'local'
}

export const isStaging: () => boolean = () => {
  const publicRuntimeConfig = envConfig()
  return publicRuntimeConfig.APP_ENV === 'staging'
}

export const isProduction: () => boolean = () => {
  const publicRuntimeConfig = envConfig()
  return publicRuntimeConfig.APP_ENV === 'production'
}

export type EnvConfigType = {
  STRIPE_KEY?: string,
  FIREBASE_AUTH_NAME?: string,
  FIREBASE_DB_URL?: string,
  FIREBASE_KEY?: string,
  FIREBASE_MSG_SDR_ID?: string,
  FIREBASE_PROJECT_ID?: string,
  FIREBASE_STORAGE?: string,
  APP_ENV?: string,
  FIREBASE_APP_ID?: string,
  CONTENTFULL_SPACEID?: string,
  CONTENTFULL_ACCESS_TOKEN?: string,
  FIREBASE_API_KEY?: string,
  SEGMENT_APP_ID?: string,
  GOOGLE_PLACE_KEY?: string,
  FIREBASE_MEASUREMENT_ID?: string,
  BRANCH_KEY?: string,
  INTERCOM_APP_ID?: string
}

export function envConfig (): EnvConfigType {
  const envVars: EnvConfigType = {}
  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
    envVars.STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY_PROD
    envVars.FIREBASE_AUTH_NAME = process.env.NEXT_PUBLIC_FIREBASE_AUTH_NAME_PROD
    envVars.FIREBASE_DB_URL = process.env.NEXT_PUBLIC_FIREBASE_DB_URL_PROD
    envVars.FIREBASE_KEY = process.env.NEXT_PUBLIC_FIREBASE_KEY_PROD
    envVars.FIREBASE_MSG_SDR_ID = process.env.NEXT_PUBLIC_FIREBASE_MSG_SDR_ID_PROD
    envVars.FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID_PROD
    envVars.FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_PROD
    envVars.FIREBASE_STORAGE = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_PROD
    envVars.BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY_PROD
    envVars.INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID_PROD
    envVars.SEGMENT_APP_ID = process.env.NEXT_PUBLIC_SEGMENT_APP_ID_PROD
    envVars.FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID_PROD
    envVars.FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_KEY_PROD
    envVars.APP_ENV = 'production'
  } else {
    envVars.STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY_DEV
    envVars.FIREBASE_AUTH_NAME = process.env.NEXT_PUBLIC_FIREBASE_AUTH_NAME_DEV
    envVars.FIREBASE_DB_URL = process.env.NEXT_PUBLIC_FIREBASE_DB_URL_DEV
    envVars.FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_KEY_DEV
    envVars.FIREBASE_MSG_SDR_ID = process.env.NEXT_PUBLIC_FIREBASE_MSG_SDR_ID_DEV
    envVars.FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_DEV
    envVars.FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID_DEV
    envVars.FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID_DEV
    envVars.FIREBASE_STORAGE = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_DEV
    envVars.INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID_DEV
    envVars.SEGMENT_APP_ID = process.env.NEXT_PUBLIC_SEGMENT_APP_ID_DEV
    envVars.APP_ENV = process.env.NEXT_PUBLIC_APP_ENV
  }
  return envVars
}
