// @flow
import type { SubscriptionTextType } from '../flowTypes'

export const ANNUAL_PRICE_STRING = '$69.99'
export const ANNUAL_15_OFF_PRICE_STRING = '$59.49'
export const ANNUAL_20_OFF_PRICE_STRING = '$55.99'
export const ANNUAL_25_OFF_PRICE_STRING = '$52.49'
export const ANNUAL_30_OFF_PRICE_STRING = '$48.99'
export const ANNUAL_50_OFF_PRICE_STRING = '$34.99'

export const SIX_MONTHS_PRICE_STRING = '$34.99'
export const SIX_MONTHS_25_OFF_PRICE_STRING = '$26.29'
export const SIX_MONTHS_50_OFF_PRICE_STRING = '$17.50'

export function getSubscriptionText (
  plan?: 'monthly' | 'annual' | 'six_months',
  promoCode: string | null = null,
  isSubscribed: boolean = false,
  isGift: boolean = false
): SubscriptionTextType {
  if (!plan) {
    return {}
  }

  // default info
  let price = ANNUAL_PRICE_STRING
  let trial = true
  let renewing = false
  let crossOutText = false
  let badgeText = '7 Days Free'
  let trialDays = 7

  if (isSubscribed) {
    trial = false
    renewing = true
  }
  let subTimePeriod = 'first year'
  if (isSubscribed) {
    subTimePeriod = 'next year'
  }
  let trialTimePeriod = 'in one week'
  if (isSubscribed) {
    trialTimePeriod = 'at the end of your current subscription'
  }
  let trialLength = 'one week trial'
  if (isSubscribed) {
    trialLength = ''
  }
  let duration = 'annual'
  let promoText = ''
  let overrideSubscribeText: ?string = null
  let annualBilledText

  let subscriptionPromoText
  let promoBannerText
  let promoHeaderText = ['Get Dipsea for', '7 Days Free']
  let promoCtaText
  let promoPaywallHeaderText
  let promoPaywallDescriptionText
  let promoConfirmationHeaderText

  if (plan === 'monthly') {
    badgeText = undefined
    price = '$12.99'
    trialTimePeriod = 'immediately'
    subTimePeriod = 'first month'
    trial = false
    duration = 'monthly'
    trialLength = ''
    trialDays = 0
    promoHeaderText = ['Get Unlimited Access', '', 'to Dipsea']
    subscriptionPromoText = undefined

    if (promoCode === 'onedollarpermonthforthreemonths1') {
      price = '$1.00'
      overrideSubscribeText = 'Your card will be charged a sum of $1.00 USD and will auto-renew for $1.00 USD per month for three months, then $12.99 per month thereafter until you cancel.'
      promoText += '$1.00 / MONTH FOR 3 MONTHS (90% off)'
      crossOutText = true
      promoBannerText = ['Get an exclusive', '90% Off', 'Dipsea.']
      promoHeaderText = ['Get Dipsea for', '90% Off']
      subscriptionPromoText = `$1/month (90% off),\nfor 3 months`
    } else if (promoCode === 'onedollaramonthfortwelvemonths1') {
      price = '$1.00'
      overrideSubscribeText = 'Your card will be charged a sum of $1.00 USD and will auto-renew for $1.00 USD per month for twelve months, then $12.99 per month thereafter until you cancel.'
      promoText += '$1.00 / MONTH FOR 12 MONTHS (90% off)'
      crossOutText = true
      promoBannerText = ['Get an exclusive', '90% Off', 'Dipsea.']
      promoHeaderText = ['Get Dipsea for', '90% Off']
      subscriptionPromoText = `$1/month (90% off),\nfor 12 months`
    } else if (promoCode === 'twomonthsfreetrial') {
      trialDays = 60
      badgeText = '60 Days Free'
      trialTimePeriod = 'in 60 days'
      trialLength = 'the 60 day trial'
      promoText = '+ 60 DAYS FREE TRIAL'
      trial = true
      promoBannerText = ['Get an exclusive', '60 Days Free Trial', 'to Dipsea.']
      promoHeaderText = ['Get Dipsea for', '60 Days Free']
    } else if (promoCode === 'sixdollarspermonthforsixmonths') {
      price = '$6.99'
      promoText += '$6.99 / MONTH FOR 6 MONTHS'
      crossOutText = true
      promoBannerText = ['Get Dipsea for', '$6.99/Month', '']
      promoHeaderText = ['Get Dipsea for', 'Only $6.99/Month']
      subscriptionPromoText = `${price}/month,\nfor 6 months`
      overrideSubscribeText = 'Your card will be charged a sum of $6.99 USD and will auto-renew for $6.99 USD per month for six months, then $12.99 per month thereafter until you cancel.'
    } else if (promoCode) {
      promoText = 'PROMOTIONS ONLY APPLY TO ANNUAL SUBSCRIPTION. Please select an annual plan to redeem this promotion.'
    }
  }

  if (plan === 'six_months') {
    badgeText = undefined
    price = SIX_MONTHS_PRICE_STRING
    trialTimePeriod = 'immediately'
    trial = false
    crossOutText = true
    duration = 'six_months'
    trialLength = ''
    trialDays = 0
    promoHeaderText = [`6 Months of Dipsea for ${SIX_MONTHS_PRICE_STRING}`]
    subscriptionPromoText = undefined

    if (isGift) {
      promoHeaderText = [`Gift 6 Months of Dipsea for ${SIX_MONTHS_PRICE_STRING}`]
    }

    switch (promoCode) {
      case '25percentoff':
        price = SIX_MONTHS_25_OFF_PRICE_STRING
        promoText = '25% Off'

        if (isGift) {
          promoHeaderText = [`Gift 6 Months of Dipsea for ${price}`, `${SIX_MONTHS_PRICE_STRING}`]
        }
        break
      case 'fiftypercentoff':
        price = SIX_MONTHS_50_OFF_PRICE_STRING
        promoText = '50% Off'

        if (isGift) {
          promoHeaderText = [`Gift 6 Months of Dipsea for ${price}`, `${SIX_MONTHS_PRICE_STRING}`]
        }
        break
    }
  }

  if (plan === 'annual' && !isGift) {
    annualBilledText = 'billed annually when trial ends'
  }

  if (plan === 'annual' && isGift) {
    promoHeaderText = [`Gift 1 Year of Dipsea for ${ANNUAL_PRICE_STRING}`]
  }

  if (promoCode && plan === 'annual') {
    switch (promoCode) {
      // Trial only - no discount
      case 'oneweekfreetrial':
        trialDays = 7
        badgeText = '7 Days Free'
        trialTimePeriod = 'in one week'
        trialLength = 'one week trial'
        promoText = `7 Days Free Trial`
        promoBannerText = ['Get an exclusive', '7 Day Free Trial', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '7 Days Free']
        promoCtaText = 'Get 7 Days Free'
        promoPaywallHeaderText = 'Get 7 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${ANNUAL_PRICE_STRING} USD billed at trial end.`
        break
      case 'twoweeksfreetrial':
        trialDays = 14
        badgeText = '14 Days Free'
        trialTimePeriod = 'in two weeks'
        trialLength = 'two week trial'
        promoText = '+ TWO WEEK FREE TRIAL'
        promoBannerText = ['Get an exclusive', '14 Day Free Trial', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '14 Days Free']
        promoCtaText = 'Get 14 Days Free'
        promoPaywallHeaderText = 'Get 14 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${ANNUAL_PRICE_STRING} USD billed at trial end.`
        promoConfirmationHeaderText = 'Your 14 Days Free Start Now'
        break
      case 'onemonthfreetrial':
        trialDays = 30
        badgeText = '30 Days Free'
        trialTimePeriod = 'in 30 days'
        trialLength = '30 day trial'
        promoText = '+ ONE MONTH FREE TRIAL'
        promoBannerText = ['Get an exclusive', '30 Day Free Trial', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '30 Days Free']
        promoCtaText = 'Get 30 Days Free'
        promoPaywallHeaderText = 'Get 30 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${ANNUAL_PRICE_STRING} USD billed at trial end.`
        promoConfirmationHeaderText = 'Your 30 Days Free Start Now'
        break
      case 'threemonthsfreetrial':
        trialDays = 90
        badgeText = '90 Days Free'
        trialTimePeriod = 'in 90 days'
        trialLength = '90 day trial'
        promoText = '+ 90 DAY FREE TRIAL'
        promoBannerText = ['Get an exclusive', '90 Day Free Trial', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '90 Days Free']
        promoCtaText = 'Get 90 Days Free'
        promoPaywallHeaderText = 'Get 90 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${ANNUAL_PRICE_STRING} USD billed at trial end.`
        promoConfirmationHeaderText = 'Your 90 Days Free Start Now'
        break
      case '4799-price':
        // no trial, $12.00 off forever
        trialDays = 0
        annualBilledText = 'billed annually'
        trial = false
        badgeText = undefined
        price = '$47.99'
        promoText = '/year'
        trialTimePeriod = 'immediately'
        trialLength = ''
        subscriptionPromoText = `${price}/year forever\n(only 3.99/month)`
        crossOutText = true
        promoBannerText = ['Get', '$12 Off Forever', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', `Only ${price}/Year`]
        promoCtaText = 'Claim this offer'
        promoPaywallHeaderText = 'Get 12$ Off Forever'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        break
      case '4799-price-7-day-trial':
        // 7 day trial, $12.00 off forever
        trialDays = 7
        price = '$47.99'
        promoText = '/year forever + 7 days free'
        trialTimePeriod = 'in one week'
        trialLength = 'one week trial'
        subscriptionPromoText = `7 days free\n${price}/year forever`
        crossOutText = true
        promoBannerText = ['Get an exclusive', '$12 Off Forever and 7 Day Free Trial', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', `Only ${price}/Year`]
        promoCtaText = 'Get 7 Days Free'
        promoPaywallHeaderText = 'Get 12$ Off Forever and 7 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        break
      case '15percentoff':
        // 7 day trial, 15% off once, renew at full price
        trialDays = 7
        badgeText = '7 Days Free'
        price = ANNUAL_15_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(15% Off)'
        subscriptionPromoText = `${price} (15% off)`

        if (!isSubscribed && !isGift) {
          promoText += ' + 7 days free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '7 Day Free Trial and 15% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '15% off']
          promoCtaText = 'Get 7 Days Free'
          promoPaywallHeaderText = 'Get 15% Off and 7 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s>.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '15% Off'
          annualBilledText = 'billed annually, renews at full price'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '15% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '15% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 15% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '15% Off'
          annualBilledText = 'billed annually, renews at full price'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '15% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '15% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 15% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case '20percentoff':
        // 7 day trial, 20% off once, renew at full price
        trialDays = 7
        badgeText = '7 Days Free'
        price = ANNUAL_20_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(20% Off)'
        subscriptionPromoText = `${price} (20% off)`

        if (!isSubscribed && !isGift) {
          promoText += ' + 7 days free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '7 Day Free Trial and 20% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '20% off']
          promoCtaText = 'Get 7 Days Free'
          promoPaywallHeaderText = 'Get 20% Off and 7 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '20% Off'
          annualBilledText = 'billed annually, renews at full price'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '20% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '20% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 20% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '20% Off'
          annualBilledText = 'billed annually, renews at full price'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '20% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '20% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 20% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case '25percentoff':
      case '25percentoff+oneweekfreetrial':
        // 7 day trial, 25% off once, renew at full price
        trialDays = 7
        badgeText = '7 Days Free'
        price = ANNUAL_25_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(25% Off)'
        subscriptionPromoText = `${price} (25% off)`
        if (!isSubscribed && !isGift) {
          promoText += ' + 7 day free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '7 Day Free Trial and 25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Get 7 Days Free'
          promoPaywallHeaderText = 'Get 25% Off and 7 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '25% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 25% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '25% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 25% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case '25percentoff+onemonthfreetrial':
        // 7 day trial, 25% off once, renew at full price
        trialDays = 30
        badgeText = '30 Days Free'
        price = ANNUAL_25_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(25% Off)'
        subscriptionPromoText = `${price} (25% off)`

        if (!isSubscribed && !isGift) {
          promoText += ' + 30 day free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one month for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '30 Day Free Trial and 25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Get 30 Days Free'
          promoPaywallHeaderText = 'Get 25% Off and 30 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '25% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 25% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '25% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '25% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '25% off']
          promoCtaText = 'Claim this offer'
          promoPaywallHeaderText = 'Get 25% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        break
      case '30percentoff':
        // 7 day trial, 30% off once, renew at full price
        trialDays = 7
        badgeText = '7 Days Free'
        price = ANNUAL_30_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(30% Off)'
        subscriptionPromoText = `${price} (30% off)`

        if (!isSubscribed && !isGift) {
          promoText += ' + 7 day free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '7 Day Free Trial and 30% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '30% off']
          promoCtaText = 'Get 7 Days Free'
          promoPaywallHeaderText = 'Get 30% Off and 7 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '30% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '30% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '30% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 30% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '30% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '30% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '30% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 30% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case '30percentoffNoTrial':
        // NO TRIAL, 30% off once, renew at full price
        trialDays = 0
        price = ANNUAL_30_OFF_PRICE_STRING
        crossOutText = true
        promoText = '(30% Off)'
        trial = false
        badgeText = undefined
        trialLength = 'your current subscription'
        annualBilledText = 'billed annually, renews at full price'
        overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
        subscriptionPromoText = `${price} (30% off)`
        promoBannerText = ['Get an exclusive', '30% Off', 'a year of Dipsea.']
        promoHeaderText = ['Get Dipsea for', '30% off']
        promoCtaText = 'claim this offer'
        promoPaywallHeaderText = 'Get 30% Off'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case 'onemonthfree1':
        // there is no trial with this coupon
        trialDays = 0
        trial = false
        badgeText = undefined
        promoText = '(1 month free)'
        price = '$54.99'
        crossOutText = true
        trialTimePeriod = 'immediately'
        trialLength = ''
        annualBilledText = 'billed annually'
        overrideSubscribeText = `Your card will be charged a sum of ${price} USD today for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
        subscriptionPromoText = `${price} (1 month free)`
        promoBannerText = ['Get an exclusive', '1 Month Free', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea with', '1 Month Free']
        promoCtaText = 'Claim this offer'
        promoPaywallHeaderText = 'Get 1 Month Free'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        break
      case 'fiftypercentoffNoTrial':
        trial = false
        trialDays = 0
        promoText = '50% Off'
        badgeText = undefined
        price = ANNUAL_50_OFF_PRICE_STRING
        crossOutText = true
        subscriptionPromoText = `${price} (50% off)`
        trialLength = ''
        annualBilledText = 'billed annually'
        if (isSubscribed) {
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '50% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '50% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 50% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else {
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '50% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '50% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 50% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case 'fiftypercentoff':
        // 7 day trial, 50% off once, renew at full price
        trialDays = 7
        promoText = '(50% Off)'
        badgeText = '7 Days Free'
        price = ANNUAL_50_OFF_PRICE_STRING
        crossOutText = true
        subscriptionPromoText = `${price} (50% off)`
        if (!isSubscribed && !isGift) {
          promoText += ' + 7 day free trial'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          promoBannerText = ['Get an exclusive', '7 Day Free Trial and 50% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '50% off']
          promoCtaText = 'Get 7 Days Free'
          promoPaywallHeaderText = 'Get 50% Off and 7 Days Free'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else if (!isGift) {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '50% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD at the end of your current subscription for a year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '50% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '50% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 50% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        } else {
          trialDays = 0
          trial = false
          badgeText = undefined
          promoText = '50% Off'
          trialLength = 'your current subscription'
          overrideSubscribeText = `Your card will be charged a sum of ${price} USD immediately for the first year of unlimited access to Dipsea. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD in exactly one year and each year thereafter until you cancel.`
          annualBilledText = 'billed annually, renews at full price'
          promoBannerText = ['Get an exclusive', '50% Off', 'a year of Dipsea.']
          promoHeaderText = ['Get Dipsea for', '50% off']
          promoCtaText = 'claim this offer'
          promoPaywallHeaderText = 'Get 50% Off'
          promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD.`
        }
        if (isGift) {
          promoHeaderText = [`Gift 1 Year of Dipsea for ${price}`, `${ANNUAL_PRICE_STRING}`]
        }
        break
      case '50percentoffforever+oneweekfreetrial':
        // 7 day trial, 50% Off Forever
        if (isGift) {
          // not offered for gift
          break
        }
        trialDays = 7
        badgeText = '7 Days Free'
        promoText = '(50% off) forever + 7 day free trial'
        price = ANNUAL_50_OFF_PRICE_STRING
        crossOutText = true
        overrideSubscribeText = `Your card will be charged a sum of ${price} USD in one week for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${price} USD in exactly one year and each year thereafter until you cancel.`
        subscriptionPromoText = `${price} (50% off) forever`
        promoBannerText = ['Get an exclusive', '7 Day Free Trial and 50% Off Forever', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '50% Off Forever']
        promoCtaText = 'Get 7 Days Free'
        promoPaywallHeaderText = 'Get 50% Off Forever and 7 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        break
      case '50percentoffforever+onemonthfreetrial':
        if (isGift) {
          // not offered for gift
          break
        }
        trialDays = 30
        badgeText = '30 Days Free'
        promoText = '(50% off forever) + 30 day free trial'
        price = ANNUAL_50_OFF_PRICE_STRING
        crossOutText = true
        overrideSubscribeText = `Your card will be charged a sum of ${price} USD in 30 days for the first year of unlimited access to Dipsea. You must cancel your subscription if you do not want to be charged. Your annual subscription will auto-renew for ${price} USD in exactly one year and each year thereafter until you cancel.`
        subscriptionPromoText = `${price} (50% off) forever`
        promoBannerText = ['Get an exclusive', '30 Day Free Trial and 50% Off Forever', 'to Dipsea.']
        promoHeaderText = ['Get Dipsea for', '50% Off Forever']
        promoCtaText = 'Get 30 Days Free'
        promoPaywallHeaderText = 'Get 50% Off Forever and 30 Days Free'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        promoConfirmationHeaderText = 'Your 30 Days Free Start Now'
        break
      case 'twomonthfreetrial+oneyear20dollars1':
        if (isGift) {
          // not offered for gift
          break
        }
        trialDays = 60
        badgeText = '60 Days Free'
        trialTimePeriod = 'in 60 days'
        trialLength = 'the 60 day trial'
        promoText = '+ 60 day free trial'
        price = '$20.00'
        crossOutText = true
        overrideSubscribeText = `Your card will be charged a sum of ${price} USD in 60 days for the first year of unlimited access to Dipsea. You must cancel your subscription before the end of the 60 day trial if you do not want to be charged. Your annual subscription will auto-renew for ${ANNUAL_PRICE_STRING} USD thereafter until you cancel.`
        subscriptionPromoText = `${price} for the first year`
        promoBannerText = ['Get an exclusive', '60 Day Free Trial and pay $20', 'for a year of Dipsea.']
        promoHeaderText = ['Get Dipsea for', 'Only $20/Year']
        promoCtaText = 'Get 60 Days Free'
        promoPaywallHeaderText = 'Get 60 Days Free and pay $20 for your first year'
        promoPaywallDescriptionText = `Annual subscription of ${price} <s>${ANNUAL_PRICE_STRING}</s> USD billed at trial end.`
        promoConfirmationHeaderText = 'Your 60 Days Free Start Now'
        break
    }
  }

  const subscribeText =
    overrideSubscribeText ||
    `Your card will be charged a sum of ${price} USD ${trialTimePeriod} for the ${subTimePeriod} of unlimited access to Dipsea.
      ${trial || renewing
          ? `You must cancel your subscription before the end of your ${trialLength} if you do not want to be charged.`
          : ''
      }
      Your ${duration} subscription will auto-renew for ${price} USD thereafter until you cancel.`

  return {
    badgeText,
    plan,
    price,
    trial,
    subTimePeriod,
    trialTimePeriod,
    trialLength,
    duration,
    subscribeText,
    promoText,
    crossOutText,
    annualBilledText,
    promoBannerText,
    promoHeaderText,
    promoCtaText,
    promoPaywallHeaderText,
    promoPaywallDescriptionText,
    promoConfirmationHeaderText,
    trialDays,
    subscriptionPromoText
  }
}
