// @flow
import type { SubscribeType } from '../models/Subscribe'
import {
  cancelStripeRenewalAsync,
  getStripePurchaseDataAsync,
  monitorApplyNewCouponSubscription,
  monitorUpdatePaymentMethod,
  subscribe
} from '../models/Subscribe'
import * as actionTypes from './actionTypes'
import type { Dispatch } from 'redux'
import type { AppStateType } from '../reducers/appstate'
import type { RequestStatusType, StripePurchasesRecordType, SubscriptionLengthType } from '../flowTypes'
import { events } from '../services/analytics'
import { fetchUserAsync, userSubscriptionExpiring } from '../models/User'
import { openSubscribeConfirmed, pushWithUtms } from './navigationActions'
import { matchPromos } from '../helpers/promos'
import { getSubscriptionText } from '../helpers/subscriptionHelpers'
import { togglePromoModal, setHeaderCopyForAuthAndSubscribeModals } from './promoActions'
import { currentUser } from '../services/firebaseAuth'
import { onRecordChange, setRecordAsync, updateRecordAsync } from '../services/firebaseDatabase'
import Router from 'next/router'
import { reportError } from '../services/bugSnag'
import { REQUEST_STATUS } from '../flowTypes'
import { loadUserAsync, userFetched } from './userActions'

export function applyPromoCode (userEnteredPromo: string, isGift: boolean = false, isMarketingLandingPage: boolean = false): any {
  return (dispatch: Dispatch<any>, getState: () => AppStateType): any => {
    const state = getState()
    const subLength: SubscriptionLengthType = state?.subscriptionPurchase?.plan ?? 'annual'
    const isSubscribed = Boolean(state?.user?.isSubscribed)
    const match = matchPromos(userEnteredPromo, subLength, isGift, state.featureAnnualOnly)
    const promoCode = match.isValid ? match.promo : undefined
    const subscriptionText = getSubscriptionText(subLength, promoCode, isSubscribed, isGift)

    if (isMarketingLandingPage) {
      // if it's from the marketing landing page, set promo as oneweekfreetrial
      events.promo_code_applied({
        promotion_id: (userEnteredPromo || '').toLowerCase(),
        promotion_name: (userEnteredPromo || '').toLowerCase()
      })

      const promo = 'oneweekfreetrial'
      const subscriptionText = getSubscriptionText(subLength, promo, isSubscribed, isGift)

      return dispatch({
        type: actionTypes.SUBSCRIBE_PROMO_CODE_SUCCESS,
        userEnteredPromo,
        promo,
        subscriptionText
      })
    }

    if (match.isValid) {
      if (match.creatorDisplayName) {
        dispatch({
          type: actionTypes.CREATOR_PROMO_APPLIED,
          creatorDisplayName: match.creatorDisplayName
        })
      }

      events.promo_code_applied({
        promotion_id: (userEnteredPromo || '').toLowerCase(),
        promotion_name: (userEnteredPromo || '').toLowerCase()
      })

      try {
        const params = new URLSearchParams(window?.location?.search)
        const promo = params.get('promo')
        if (!promo || promo !== userEnteredPromo) {
          if (promo !== userEnteredPromo) {
            params.set('promo', userEnteredPromo)
          } else {
            params.append('promo', userEnteredPromo)
          }
          Router.push(`${window.location.pathname}?${params.toString()}`)
        }
      } catch (e) {
        console.log(e)
      }

      return dispatch({
        type: actionTypes.SUBSCRIBE_PROMO_CODE_SUCCESS,
        userEnteredPromo,
        welcomeBannerText: match.welcomeBannerText,
        promo: match.promo,
        subscriptionText
      })
    } else {
      events.promo_code_denied({
        promotion_id: userEnteredPromo,
        promotion_name: userEnteredPromo
      })
      return dispatch({
        type: actionTypes.SUBSCRIBE_PROMO_CODE_ERROR,
        userEnteredPromo,
        subscriptionText: {
          ...subscriptionText,
          promoError: match.errorMessage
        }
      })
    }
  }
}

export function clearPromoCode (isGift: boolean = false): any {
  return (dispatch: Dispatch<any>, getState: () => AppStateType): void => {
    const state = getState()
    const plan = state?.subscriptionPurchase?.plan
    const isSubscribed = Boolean(state?.user?.isSubscribed)
    const subscriptionText = getSubscriptionText(plan, undefined, isSubscribed, isGift)

    try {
      const params = new URLSearchParams(window?.location?.search)
      const promo = params.get('promo')
      if (promo) {
        params.delete('promo')
        Router.push(`${window.location.pathname}?${params.toString()}`)
      }
    } catch (e) {
      console.log(e)
    }

    return dispatch({
      type: actionTypes.SUBSCRIBE_PROMO_CODE_RESET,
      subscriptionText
    })
  }
}

export function changePlanAction (plan: SubscriptionLengthType, changeToPaymentForm: boolean = true, isGift: boolean = false): any {
  return (dispatch: Dispatch<any>, getState: () => AppStateType): any => {
    const state = getState()
    const promo = (state.subscriptionPromo || {}).promo
    const subscriptionText = getSubscriptionText(plan, promo, !!(state.user || {}).isSubscribed, isGift)

    if (!isGift) {
      dispatch(showPaymentFormOnPaywall(changeToPaymentForm))
    }
    return dispatch({
      type: actionTypes.SUBSCRIBE_CHANGE_PLAN,
      plan,
      subscriptionText
    })
  }
}

export function subscribeAction (stripeObj: any, cardNumberElement?: any, altPaymentEvent?: any, isDarkTheme: boolean = false): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<any> => {
    const state = getState()

    const {
      subscriptionPurchase: {
        plan,
        name
      }
    } = state
    const referId = state.subscriptionReferId
    try {
      if (!plan) {
        return dispatch({
          type: actionTypes.SUBSCRIBE_PURCHASE_ERROR,
          error: 'Please select a plan.'
        })
      }
      const user = currentUser()
      if (!user) {
        return dispatch({
          type: actionTypes.SUBSCRIBE_PURCHASE_ERROR,
          error: 'Please log in to subscribe.'
        })
      }
      const {
        id,
        email
      } = user
      if (!altPaymentEvent && (!name || name.length < 1)) {
        return dispatch({
          type: actionTypes.SUBSCRIBE_PURCHASE_ERROR,
          error: 'Please enter the name on your card.'
        })
      }
      await dispatch({
        type: actionTypes.SUBSCRIBE_PURCHASE
      })

      let response
      if (altPaymentEvent) {
        response = altPaymentEvent
      } else {
        response = await stripeObj.createToken(cardNumberElement, {
          type: 'card',
          name,
          email
        })
      }

      if (response && response.token) {
        await dispatch({
          type: actionTypes.SUBSCRIBE_STRIPE_TOKEN,
          token: response.token
        })
      } else {
        const errResponse = response || {}
        const error = errResponse.error || {}
        const message = error.message || 'Invalid credit card number. Please try again.'
        return dispatch({
          type: actionTypes.SUBSCRIBE_PURCHASE_ERROR,
          error: message
        })
      }

      const userPromo = state.subscriptionPromo ? state.subscriptionPromo.userEnteredPromo : undefined
      const attrs: SubscribeType = {
        userId: id,
        email,
        plan,
        promo: state.subscriptionPromo ? state.subscriptionPromo.promo : undefined,
        userEnteredPromo: userPromo,
        paymentSource: response.token
      }

      if (referId) {
        attrs.referId = referId
      }
      const userRecord = await fetchUserAsync(id)
      attrs.userExpiring = userSubscriptionExpiring(userRecord)

      await subscribe(attrs)

      dispatch({
        type: actionTypes.SUBSCRIBE_PURCHASE_SUCCESS
      })

      if (state.subscriptionPurchase.plan === 'annual') {
        events.user_selected_annual_subscription({
          refer_id: referId || undefined,
          promotion_id: referId ? 'referafriend' : userPromo,
          stripeToken: response.token.id,
          winback: !!userRecord.subscription_expires_at || userRecord.churned
        })
      } else if (state.subscriptionPurchase.plan === 'monthly') {
        events.user_selected_monthly_subscription({
          refer_id: referId || undefined,
          promotion_id: referId ? 'referafriend' : userPromo,
          winback: !!userRecord.subscription_expires_at || userRecord.churned
        })
      }

      if (altPaymentEvent) {
        events.cta_button_clicked({
          cta_name: 'pay_with_saved_card',
          url: (window.location || {}).href
        })
        altPaymentEvent.complete('success')
      }

      if (state.promoFlowModalOpen) {
        dispatch(togglePromoModal())
      }

      const textSubscriptionConfirmationHeader = state?.subscriptionText?.promoConfirmationHeaderText || null
      await dispatch(
        setHeaderCopyForAuthAndSubscribeModals(
          null,
          null,
          textSubscriptionConfirmationHeader,
          null
        )
      )

      if (!window.location.pathname.includes('/themes/')) {
        dispatch(openSubscribeConfirmed(isDarkTheme))
      }
      dispatch(clearPromoCode())
      dispatch(loadUserAsync())
    } catch (error) {
      const formatError = typeof error === 'string' ? error : 'An error occurred, please reload the page and try again or contact support.'
      console.error(error)
      if (altPaymentEvent) {
        altPaymentEvent.complete('fail')
      }
      return dispatch({
        type: actionTypes.SUBSCRIBE_PURCHASE_ERROR,
        error: formatError
      })
    }
  }
}

export function addNameAction (name: string): actionTypes.SubscribeAddNameType {
  return {
    type: actionTypes.SUBSCRIBE_ADD_NAME,
    name
  }
}

export function toggleSubscriptionModal (): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    const state = getState()
    if (state.subscriptionModalOpen) {
      events.paywall_dismiss()
    } else {
      // Every time we open the paywall modal, we set the plan to annual and to show the first page of plan options, ie not payment form
      await dispatch(changePlanAction('annual', false))
      events.opened_unlock_cta()
      events.paywall_show()
    }
    dispatch({
      type: actionTypes.SUBSCRIBE_MODAL_TOGGLE
    })
  }
}

export function showPaymentFormOnPaywall (isOnPayment: boolean): actionTypes.ShowPaymentModalFormOnPaywallType {
  return {
    type: actionTypes.SHOW_PAYMENT_FORM_ON_PAYWALL,
    isOnPayment
  }
}

export type GiveAwayType = {
  subscriptionLength: 'year' | 'month' | 'twoMonths' | 'threeMonths' | 'sixMonths', // Not the same as SubscriptionLengthType
  code: string,
  expiresAt?: number
}

export function applyGiveAwayCode (code: GiveAwayType, callback: () => void): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    const state = getState()
    const { user } = state

    dispatch({
      type: actionTypes.REDEEMING_GIFT
    })

    if (code.expiresAt && code.expiresAt < Date.now()) {
      events.giveAwayCodeError({
        code: code.code,
        length: code.subscriptionLength,
        error: 'codeExpired'
      })
      return dispatch({
        type: actionTypes.REDEEM_GIFT_FAIL,
        msg: 'codeExpired'
      })
    }

    if (user.isSubscribed) {
      callback()
      return dispatch({
        type: actionTypes.REDEEM_GIFT_SUCCESS
      })
    }

    try {
      const userParams = {
        isSubscribed: true,
        subscribed_at: Date.now(),
        subscription_type: 'gift',
        subscription_expires_at: Date.now() + 365 * 24 * 60 * 60 * 1000,
        subscription_length: 'annual'
      }

      if (code.subscriptionLength === 'month') {
        userParams.subscription_length = 'monthly'
        userParams.subscription_expires_at = Date.now() + 30 * 24 * 60 * 60 * 1000
      }

      if (code.subscriptionLength === 'twoMonths') {
        userParams.subscription_length = 'monthly'
        userParams.subscription_expires_at = Date.now() + 60 * 24 * 60 * 60 * 1000
      }

      if (code.subscriptionLength === 'threeMonths') {
        userParams.subscription_length = 'monthly'
        userParams.subscription_expires_at = Date.now() + 60 * 24 * 60 * 60 * 1000
      }

      if (code.subscriptionLength === 'sixMonths') {
        userParams.subscription_length = 'monthly'
        userParams.subscription_expires_at = Date.now() + 180 * 24 * 60 * 60 * 1000
      }

      await updateRecordAsync(`users/${user.id}`, userParams)
      callback()
      events.promo_subscription_started({
        subscription_type: userParams.subscription_length,
        promo: code.code,
        promotion_id: code.code,
        reward_type: 'gift',
        product_id: 'gift',
        trial: false
      })

      events.giveAwayCodeRedeemed({
        code: code.code,
        length: code.subscriptionLength
      })
      dispatch(userFetched({ ...user, ...userParams }))
      return dispatch({
        type: actionTypes.REDEEM_GIFT_SUCCESS
      })
    } catch (e) {
      reportError(e)
      return dispatch({
        type: actionTypes.REDEEM_GIFT_FAIL,
        msg: 'codeExpired'
      })
    }
  }
}

export function monitorStripePurchaseRefund (stripePurchaseId?: string): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    if (!stripePurchaseId) {
      return
    }
    if (global.refundMonitor) {
      return
    }
    global.refundMonitor = onRecordChange(`stripePurchases/${stripePurchaseId}`, (data: StripePurchasesRecordType): void => {
      dispatch({
        type: actionTypes.REFUND_STATUS_UPDATED,
        refund: data.refund
      })

      if (!data.refund) {
        setRecordAsync(`stripePurchases/${stripePurchaseId}/refund/status`, 'checkRefundEligibility')
      } else if (data.refund && data.refund.checkedAt && data.refund.checkedAt < Date.now() - 24 * 60 * 60 * 1000 && data.refund.status !== 'succeeded') {
        setRecordAsync(`stripePurchases/${stripePurchaseId}/refund/status`, 'checkRefundEligibility')
      } else if (data.refund && data.refund.amountToRefund && data.refund.status === 'succeeded') {
        events.refund_request(data.refund.amountToRefund)
      }
    })
  }
}

export function updateRefundRequest (stripePurchaseId?: string, amountToRefund: number): Promise<void> {
  if (!stripePurchaseId) {
    return Promise.resolve()
  }
  return updateRecordAsync(`stripePurchases/${stripePurchaseId}/refund`, {
    status: 'processRefund',
    amountToRefund: Math.round(amountToRefund)
  })
}

export function userCancellationCompleteAction (): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    const state = getState()
    if (!!state.user.subscription_expires_at && !!state.showCancelSubscriptionModal) {
      dispatch(setIsCancelSubscriptionModalVisible(false))
      dispatch(pushWithUtms('/account/subscription'))
    }
  }
}

export function updateCancelSubscriptionState (state: RequestStatusType): actionTypes.CancelSubscriptionStatusType {
  return {
    type: actionTypes.CANCEL_SUBSCRIPTION_STATUS,
    state
  }
}

export function setIsCancelSubscriptionModalVisible (isVisible: boolean): actionTypes.CancelSubscriptionHideModalType | actionTypes.CancelSubscriptionShowModalType {
  if (isVisible) {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_SHOW_MODAL
    }
  } else {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_CLOSE_MODAL
    }
  }
}

export function cancelStripeRenewalAction (): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    await dispatch(setIsCancelSubscriptionModalVisible(true))

    // If after 10 seconds and user is still on callcelation loading modal, close and nav back to Manage Subscription
    setTimeout((): void => {
      if (getState().showCancelSubscriptionModal) {
        dispatch(setIsCancelSubscriptionModalVisible(false))
      }
    }, 10000)

    dispatch(updateCancelSubscriptionState(REQUEST_STATUS.loading))
    const state = getState()
    const key = state.user.stripe_purchase_key
    if (key) {
      try {
        await cancelStripeRenewalAsync(key)
        events.user_cancelled_subscription({ user_id: state.user.id, stripe_key: key })
        // loadUserAsync has a listener, once it updates with user.subscription_expires_at,
        // we close the modal and nav to /account/subscription
      } catch (error) {
        dispatch(updateCancelSubscriptionState(REQUEST_STATUS.fail))
      }
    } else {
      dispatch(updateCancelSubscriptionState(REQUEST_STATUS.fail))
    }
  }
}

export function retrievePaymentInfo (): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    const state = getState()
    if (state.user.stripe_purchase_key) {
      const paymentInfo = await getStripePurchaseDataAsync(state.user.stripe_purchase_key)
      if (paymentInfo && paymentInfo.last4) {
        dispatch({
          type: actionTypes.SUBSCRIBE_FETCH_CARD_INFO,
          last4: paymentInfo.last4,
          trialEnd: paymentInfo.trialEnd ? paymentInfo.trialEnd : undefined,
          card: paymentInfo.card,
          lastPaymentSuccessful: paymentInfo.lastPaymentSuccessful
        })
      }
    }
  }
}

export function updatePaymentMethodLoadingAction (status: ?RequestStatusType): actionTypes.UpdatePaymentMethodLoadingType {
  return {
    type: actionTypes.UPDATE_PAYMENT_METHOD_STATUS,
    status
  }
}

export function updatePaymentMethodFailAction (error: string): actionTypes.UpdatePaymentMethodErrorType {
  return {
    type: actionTypes.UPDATE_PAYMENT_METHOD_ERROR,
    error
  }
}

export function updatePaymentMethodSuccessAction (last4: string): actionTypes.UpdatePaymentMethodSuccessType {
  return {
    type: actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
    last4
  }
}

export function updatePaymentMethod (stripeObj: any, cardNumberElement?: any): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    dispatch(updatePaymentMethodLoadingAction(REQUEST_STATUS.loading))
    const defaultError = 'There was an issue updating your payment method. Please refresh and try again.'
    const state = getState()
    const key = state.user.stripe_purchase_key
    if (cardNumberElement && key) {
      try {
        const response = await stripeObj.createPaymentMethod({
          type: 'card',
          card: cardNumberElement
        })
        if (response && (response || {}).paymentMethod) {
          const monitorUpdatePayment = await monitorUpdatePaymentMethod(key, response.paymentMethod)
          if (monitorUpdatePayment && typeof monitorUpdatePayment === 'boolean') {
            dispatch(updatePaymentMethodSuccessAction(response.paymentMethod.card.last4))
            events.payment_method_updated()
            dispatch(retrievePaymentInfo())
          } else {
            dispatch(updatePaymentMethodFailAction(monitorUpdatePayment || defaultError))
          }
        } else {
          dispatch(updatePaymentMethodFailAction(defaultError))
        }
      } catch (error) {
        console.log(error)
        dispatch(updatePaymentMethodFailAction(defaultError))
      }
    } else {
      dispatch(updatePaymentMethodFailAction(defaultError))
    }
  }
}

export function updateApplyCouponSubscriptionState (state: RequestStatusType): actionTypes.UpdateApplyCouponSubscriptionStatusType {
  return {
    type: actionTypes.UPDATE_APPLY_COUPON_SUBSCRIPTION_STATUS,
    state
  }
}

export function applyNewCouponSubscriptionAction (coupon_id: 'fiftypercentoff' | '30percentoff' = 'fiftypercentoff'): any {
  return async (dispatch: Dispatch<any>, getState: () => AppStateType): Promise<void> => {
    await dispatch(setIsCancelSubscriptionModalVisible(true))
    await dispatch(updateApplyCouponSubscriptionState(REQUEST_STATUS.loading))
    const state = getState()
    const key = state.user.stripe_purchase_key
    if (key) {
      const monitorStatus = await monitorApplyNewCouponSubscription(key, coupon_id)
      if (monitorStatus) {
        // worked
        dispatch(updateApplyCouponSubscriptionState(REQUEST_STATUS.success))
        await dispatch(setIsCancelSubscriptionModalVisible(false))
        dispatch(pushWithUtms('/account/subscription'))
        events.user_applied_discount_to_subscription({
          promotion_id: coupon_id,
          promotion_name: 'preventCancellation'
        })
      } else {
        // did not work, trigger normal sub flow
        dispatch(updateApplyCouponSubscriptionState(REQUEST_STATUS.fail))
      }
    }
  }
}

export function toggleSubscriptionModalAction (): actionTypes.ToggleSubscriptionModalType {
  return {
    type: actionTypes.TOGGLE_SUBSCRIPTION_MODAL
  }
}
