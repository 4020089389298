// @flow

import type { TrackPropsType } from './models/Track'
import type { SeriesPropsType } from './models/Series'
import type { BookInfoPropsType } from './models/Book'
import type { ThemeObjectType } from './models/ThematicLandingPage'

export type MarkUpDataItemType = {
  id: string,
  badgeText?: string,
  nextTrackIdForImage: string
}

export type MarkUpDataType = {
  [id: string]: MarkUpDataItemType
}

export type RecommendationNameType = 'storiesleft'

export type RecommendationType = {
  title: string,
  location: string,
  similarTracks: string[], // to be replace with => ids: string[]
  ids: string[], // tagIds | seriesIds | categoryIds | trackIds
  description?: string,
  markUpData?: MarkUpDataType,
  cta?: {
    name: string,
    deepLink: string
  },
  type: 'favorites' | 'tracks' | 'categories' | 'tags' | 'series', // ids type
  displayType: 'horizontalMediumTrackRow' | 'horizontalLargeTrackRow' | 'circularRow' | 'playableTrackRow' | 'horizontalLargeSeriesRow'
}

export type TrackRecommendationsType = {
  trackId: string,
  recommendations: RecommendationType[]
}

export type UserRecommendationsType = {
  userId: string,
  recommendations: RecommendationType[],
  // eslint-disable-next-line no-use-before-define
  featuredTracks?: FeaturedTracksType
}

export type TagToTrackType = {
  [key: string]: {
    track_ids: {
      [key: string]: number
    }
  }
}

export type TrackActorType = {
  id: string,
  characterName: string,
  hunkId?: string
}

export type TrackPreviewType = {
  previewUrl: string
}

export type TrackType = {
  id: string,
  deleted?: boolean,
  art_url: string,
  audio_url: string,
  context_ids: {
    [key: string]: number
  },
  createdAt: number,
  description: string,
  short_description?: string,
  duration: number,
  hotness_rating?: number,
  gradient_color?: string,
  new_hotness_rating?: number,
  mask_num: number,
  search_tag_ids: {
    [key: string]: number
  },
  serial_ids: {
    [key: string]: number
  },
  tag_ids: {
    [key: string]: {
      index: number
    }
  },
  title: string,
  favorited_at?: number,
  featured?: boolean,
  unlocked?: boolean,
  'image-100': string,
  'image-1000': string,
  'image-800': string,
  'image-500': string,
  publishedAt: number,
  moreLikeThis?: RecommendationType[],
  homepageBadge?: string,
  actors?: {
    [key: string]: TrackActorType
  },
  trackPreview?: TrackPreviewType,
  slugs: {
    [key: string]: number
  },
  indicatorTime?: number
}

export type TracksObjectType = {
  [key: string]: TrackType
}

export type AlgoliaTrackType = TrackType & {
  objectID: string
}

export type AlgoliaTagType = {
  name: string,
  objectID: string,
  slugs: {
    [slug: string]: 1
  }
}

export type UnlockedTracksType = string[]

export type OrderedTracksType = {
  [key: string]: {
    index: number
  }
}

export type TagType = {
  id: string,
  name: string,
  plural?: string,
  image_url?: string,
  slugs: {
    [slug: string]: number
  },
  tagType?: "wellness" | "sleep"
}

export type TagsObjectType = {
  [key: string]: TagType
}

export type FilterTagsType = { [key: string]: { index: number } }

export type SubscriptionType = 'ios' | 'gift' | 'stripe' | 'none' | 'android'

export type SubscriptionLengthType = 'monthly' | 'annual' | 'six_months'

export type UserListenType = {
  firstListenTimestamp: number,
  furthest_position: number,
  position: number,
  timestamp: number,
  completed?: number,
  max_position?: number
}

export type PurchaseSkuType =
  'com.dipsea.unlimited.annual'
  | 'com.dipsea.unlimited.monthly'
  | 'com.dipsea.unlimited.lifetime' // ios lifetime
  | 'com.dipsea.android.annual'
  | 'com.dipsea.android.monthly'
  | 'com.dipsea.ios.annual.6999'
  | 'com.dipsea.ios.monthly.1299'
  | 'prod_Eb2Ten2ze3lXh2' // Stripe prod annual
  | 'prod_ETWYTgkvID2y0g' // Stripe prod monthly
  | 'prod_EbsEA7rhaZSna5' // Stripe dev monthly
  | 'prod_EbsFbsoCNFFBOO' // Stripe dev annual

export const STRIPE_UPGRADE_STATE_TYPES = Object.freeze({
  REQUESTED: 'requested',
  FAILED: 'failed',
  SUCCESS: 'success'
})
export type StripeUpgradeStateType = $Values<typeof STRIPE_UPGRADE_STATE_TYPES>
export type UserStripeUpgradeType = {
  state?: StripeUpgradeStateType,
  timestamp?: number,
  message?: string
}
export const PROVIDERS = Object.freeze({
  email: 'password',
  google: 'google.com',
  apple: 'apple.com',
  facebook: 'facebook.com'
})
export type ProvidersType = $Values<typeof PROVIDERS>
export type StripeUpdatePaymentMethodType = {
  id: string,
  object: "payment_method",
  billing_details: {
    address: {
      city: string,
      country: string,
      line1: string,
      line2: string,
      postal_code: string,
      state: string
    },
    email: string,
    name: ?string,
    phone: string
  },
  card: {
    brand: string,
    checks: {
      address_line1_check: null,
      address_postal_code_check: null,
      cvc_check: "pass" | "fail"
    },
    country: string,
    exp_month: number,
    exp_year: number,
    fingerprint: string,
    funding: string,
    generated_from: null,
    last4: string,
    networks: {
      available: string[],
      preferred: null
    },
    three_d_secure_usage: {
      supported: boolean
    },
    wallet: null
  },
  created: number,
  customer: null,
  livemode: boolean,
  metadata: {
    [key: string]: string
  },
  type: "card"
}

export type UserCancellationSurveyAnswerType = {
  canceled_at: number,
  other_text?: string,
  users_answer: string
}

export type UserType = {
  id: string,
  email: string,
  isAnonymous?: boolean,
  ageVerified?: number,
  notification_dismissed_at?: ?number,
  notifications_enabled?: boolean,
  fcm_token?: ?string,
  isSubscribed?: boolean,
  subscription_type?: SubscriptionType,
  subscribed_at?: number,
  entitlements?: PurchaseSkuType,
  listen_count?: number,
  listens?: {
    [key: string]: UserListenType
  },
  user_dismissed_paywall_at?: number,
  dismissed_survey_at?: number,
  survey_answer?: string,
  post_fave_notification_dismissed_at?: number,
  first_category_follow_at?: number,
  dismissed_first_category_follow_modal_at?: number,
  apns_token?: string,
  churned?: boolean,
  analyticsProperties?: { // This corresponds to the user properties in firebase. we keep them stored on the user object to dynamically set in the db. The key name must match the user property in firebase
    [key: string]: string
  },
  fresh_for_you_modal_dismissed_at?: number,
  nudged_for_rating?: {
    [key: string]: number
  },
  rated_app?: {
    [key: string]: number
  },
  appVersion?: number,
  subscription_renews_at?: number,
  stripe_purchase_key?: string,
  subscription_expires_at?: number,
  subscription_length?: 'monthly' | 'annual',
  deleteAt?: number,
  upgrade?: UserStripeUpgradeType,
  referId?: string,
  totalRefers?: number,
  providers?: ProvidersType[],
  gift?: boolean,
  trial?: boolean,
  referredBy?: { // was a string
    id: string,
    first_viewed_30_day_trial?: number,
    subscribed?: boolean
  },
  cancellation_survey?: UserCancellationSurveyAnswerType
}

export type UserCategoryFollowsType = {
  [key: string]: {
    followed_at: number
  }
}
export type AskForNotificationType = {
  askForNotificationPermission: boolean,
  askForNotificationPermissionPostFave: boolean
}

export type QuestionKeyType = 'howExplicit' | 'sexualPreference' | 'genderLeadVoice' | 'dynamic'

export const QuestionKeys = {
  howExplicit: 'howExplicit',
  sexualPreference: 'sexualPreference',
  genderLeadVoice: 'genderLeadVoice',
  dynamic: 'dynamic'
}

export type QuizStateType = {
  dismissedAt?: number,
  finishedAt?: number,
  personaRecieved: boolean,
  started: boolean,
  howExplicit?: string,
  sexualPreference?: string,
  genderLeadVoice?: string,
  dynamic?: string,
  completed: boolean,
  retakeNumber: number,
  persona: string,
  unlockedTracks?: string[]
}

export type QuizModelType = {
  dismissedAt?: number,
  finishedAt?: number,
  howExplicit?: string,
  sexualPreference?: string,
  genderLeadVoice?: string,
  dynamic?: string,
  unlockedTracks: string[],
  persona: string
}

export type QuestionAnswerType = {
  userId: string,
  [questionKey: QuestionKeyType]: string
}

export type ComingSoonType = {
  isEnabled: ?boolean,
  nextEpisodeReleaseAt: ?number,
  headerText: ?string
}

export type SerialType = {
  id: string,
  name: string,
  description: string,
  index: number,
  slugs: {
    [slug: string]: number
  },
  track_ids: {
    [key: string]: {
      index: number
    }
  },
  featured?: { // FeaturedSeries SeriesCard shows on homepage
    image: string, // actor badge: circular image
    header: string, // actor badge: header
    subheader: string // actor badge: subheader
  },
  comingSoon?: ComingSoonType,
  iconBadgeText?: string, // Pill on top left of SeriesBlockIcon
  series_tag_text?: string,
  episodesLabel?: string,
  publishedAt: number,
  themes: {
    [key: string]: number
  }
}

export type CategoryPageRowType = {
  displayType: "horizontalMediumTrackRow" | "horizontalMediumBookRow" | "verticalMediumSeriesRow",
  items: TrackPropsType[] | SeriesPropsType[] | BookInfoPropsType[],
  title: string,
  ids: string[],
  description: string | null,
  cta: {
    deepLink: string,
    name: string
  } | null
}

export type CategoryType = {
  id: string,
  createdAt: number,
  description: string,
  short_description?: string,
  display_name: string,
  image_url: string,
  header_image_url: string, // header image used to replace typed display_name
  index: number,
  slide_url: string,
  track_index: {
    [key: string]: {
      index: number
    }
  },
  series_index: {
    [key: string]: {
      index: number
    }
  },
  slugs?: { [key: string]: number },
  subType?: 'stories' | 'wellness' | 'sleep' | 'hunks' | 'creator', // If there isn't a subType we assume it's stories
  pageRows: { [key: string]: CategoryPageRowType},
  actors?: {
    [key: string]: {
      id: string,
      characterName: string
    }
  },
  hunk_profile_image_url: string
}

export type CategoriesObjectType = {
  [key: string]: CategoryType
}

export type SerialsObjectType = {
  [key: string]: SerialType
}

export type UserFavoritesType = {
  [key: string]: {
    favorited_at: number
  }
}

export type AdminEnumType = 'admin' | 'staff' | 'user'

export type ObjectKeyIndexType = { [key: string]: { index: number } }

export type FeaturedTracksType = {
  [key: string]: {
    header_text: string,
    index: number
  }
}

export type GuideCategoryType = {
  category_id: string,
  category_name: string,
  name: string
}

export type SlugsType = {
  contexts: {
    [key: string]: string
  },
  tracks: {
    [key: string]: string
  },
  tags: {
    [key: string]: string
  },
  serials: {
    [key: string]: string
  },
  books: {
    [key: string]: string
  }
}

export type ProviderObjectType = {
  providerId: ProvidersType,
  uid: string,
  displayName: string,
  email: string,
  photoURL?: string
}

export type CurrentUserType = {
  email: ?string,
  isAnonymous: boolean,
  uid: string,
  providerData: ProviderObjectType[]
}

export type OnRecordChangeType = { off: () => void }

export type PlayStateType = 'none' | 'loaded' | 'playing' | 'loading' | 'stopped' | 'paused'

export type StripePurchaseDataType = {
  purchaseId: string,
  cancelled: string,
  expirationYear: string,
  expirationMonth: string,
  last4: string
}
export type RefundStatusType = 'checkRefundEligibility' | 'eligible' | 'processRefund' | 'ineligible' | 'failed' | 'succeeded'
export type RefundObjectType = {
  amountPaid?: number,
  amountToRefund?: number,
  checkedAt?: number,
  message?: string,
  refundId?: string,
  status: RefundStatusType
}

export type StripePurchasesRecordType = {
  cancelledOn: number,
  paymentSource: {
    card: {
      exp_year: number,
      exp_month: number,
      last4: number
    }
  },
  trial_end?: number,
  oldPaymentSource?: {
    exp_year: number,
    exp_month: number,
    last4: number
  },
  updatePaymentMethod?: StripeUpdatePaymentMethodType,
  updatePaymentMethodSuccess?: boolean,
  updatePaymentMethodError?: string,
  lastPaymentSuccessful?: boolean,
  refund?: RefundObjectType
}

export type PromoType = {
  reg: any,
  promo: string,
  event?: string,
  header?: string,
  subHeader?: string,
  subType?: 'annual' | 'monthly' | 'six_months',
  isAvailableOnGift?: boolean
}

export type TokenType = {
  id: string,
  object: string,
  card: {
    id: string,
    object: string,
    address_city?: string,
    address_country?: string,
    address_line1?: string,
    address_line1_check?: string,
    address_line2?: string,
    address_state?: string,
    address_zip?: string,
    address_zip_check?: string,
    brand: string,
    country: string,
    cvc_check: string,
    dynamic_last4?: number,
    exp_month: number,
    exp_year: number,
    funding: string,
    last4: string,
    metadata: {},
    name: string,
    tokenization_method?: string
  },
  client_ip: string,
  created: number,
  email: string,
  livemode: boolean,
  type: string,
  used: boolean
}
export type StripePurchaseCardType = {
  exp_month: number,
  exp_year: number
}

export type StripePurchaseType = {
  last4: string,
  trialEnd?: number,
  card?: StripePurchaseCardType,
  lastPaymentSuccessful?: boolean
}

export type SubscriptionPurchaseType = {
  inProcess: boolean,
  token?: TokenType,
  plan?: SubscriptionLengthType,
  error?: string,
  name?: string,
  last4?: string,
  isSuccessful?: boolean,
  trialEnd?: number,
  card?: StripePurchaseCardType,
  lastPaymentSuccessful?: boolean
}

export type TrackPlayerStateType = null | 'playing' | 'paused' | 'stopped' | 'loading' | 'idle' | 'ready'
export type TrackPlayerEventType =
  'update'
  | 'remote-play'
  | 'remote-pause'
  | 'remote-stop'
  | 'remote-seek'
  | 'playback-state'
  | 'playback-track-changed'
  | 'playback-error'

export type AccountPageNameType = 'subscription' | 'termsConditions ' | 'privacyPolicy ' | 'contactUs ' | 'resetPassword ' | 'logout' | 'account' | 'refer'
export type AccountPageInfoType = {
  slug: string,
  title: string,
  index: number,
  requiresAuth: boolean,
  hideNav: boolean
}
export type AccountPageType = {
  [page: AccountPageNameType]: AccountPageInfoType
}

export type ReferralType = {
  userId: string,
  referred?: {
    [userId: string]: number
  },
  viewedModal?: boolean
}

export type DeepLinkOpenedType = {
  provider: 'branch' | 'iterable',
  url: string
}

export type PromoCodeEventType = {
  promotion_id: string,
  promotion_name: string
}

export type SubscriptionPromoType = {
  promo?: string,
  userEnteredPromo?: string,
  valid?: boolean,
  isAvailableOnGift?: boolean,
  welcomeBannerText?: string
}

export type TrackPlayerAnalyticsObjectType = {
  track_id: string,
  playback_session_id?: string,
  title?: string,
  description?: string,
  first_tag_id?: string,
  second_tag_id?: string,
  third_tag_id?: string,
  first_tag_name?: string,
  second_tag_name?: string,
  third_tag_name?: string,
  all_tag_ids?: string[],
  all_tag_names?: string[],
  series_id?: string,
  series_name?: string,
  episode_number?: number,
  category_ids?: string[],
  category_names?: string[],
  duration?: number,
  audio_url?: string,
  image_100?: string,
  image_500?: string,
  image_800?: string,
  hotness_rating_3?: number,
  is_featured_track?: boolean,
  is_in_first_row_unlocked?: boolean,
  is_in_second_row_unlocked?: boolean,
  position?: number,
  pct_complete?: number,
  state?: TrackPlayerStateType,
  location?: string
}

export type SubscriptionTextType = {
  plan: 'annual' | 'monthly' | 'six_months',
  price?: string,
  trial?: boolean,
  subTimePeriod?: string,
  trialTimePeriod?: string,
  trialLength?: string,
  duration?: string,
  subscribeText?: string,
  promoText?: string,
  promoError?: string,
  crossOutText: boolean,
  badgeText?: string,
  annualBilledText?: string,
  trialDays: number,
  promoBannerText?: [string, string, string],
  promoHeaderText?: string[],
  promoCtaText?: string,
  promoPaywallHeaderText?: string,
  promoPaywallDescriptionText?: string,
  promoConfirmationHeaderText?: string,
  subscriptionPromoText?: string
}

export type PromosType = {
  name: string,
  subtype: 'monthly' | 'annual',
  isCampaign: boolean,
  pageType?: 'educational',
  stripeCoupon?: string,
  trialDuration?: number,
  cta?: string,
  endDate?: number,
  startDate?: number,
  header?: string,
  subheader?: string,
  valueProp1Header?: string,
  valueProp1Subheader?: string,
  valueProp2Header?: string,
  valueProp2Subheader?: string,
  valueProp3Header?: string,
  valueProp3Subheader?: string,
  bannerText?: string,
  defaultSignIn?: boolean,
  iconType?: any,
  subscriptionOfferCta?: string,
  subscriptionOfferTitle?: string,
  subscriptionOfferSubtitle?: string,
  subscriptionOfferSubtext?: string
}

export type PromoStateObjectType = {
  isValid?: boolean,
  isLoading?: boolean,
  promoCode: string,
  errorMessage?: string,
  data?: PromosType
}

export type ValidatedPromosType = {
  name: string,
  subtype: string,
  promoCode: string,
  cta: string,
  header: string,
  subheader?: string,
  valueProp1Header: string,
  valueProp1Subheader?: string,
  valueProp2Header: string,
  valueProp2Subheader?: string,
  valueProp3Header: string,
  valueProp3Subheader?: string,
  bannerText: string,
  defaultSignIn: boolean,
  iconType: any,
  subscriptionOfferCta: string,
  subscriptionOfferTitle: string,
  subscriptionOfferSubtitle: string,
  subscriptionOfferSubtext: string
}

export type PromoContentPromosType = {
  name: string,
  promoCode: string,
  cta: string,
  header: string,
  subheader?: string,
  valueProp1Header: string,
  valueProp1Subheader?: string,
  valueProp2Header: string,
  valueProp2Subheader?: string,
  valueProp3Header: string,
  valueProp3Subheader?: string
}

export type PageSharedType = {
  share_id: string,
  page_url: string,
  platform: string,
  track_id: string
}

export type FirebaseUserCredentialType = {
  additionalUserInfo?: ?{
    isNewUser: boolean,
    profile: ?any,
    providerId: 'apple' | 'email' | 'facebook' | 'google',
    username?: string | null
  },
  credential: ?{
    providerId: string,
    signInMethod: string
  },
  operationType?: ?string,
  user: ?{
    email?: string,
    uid: string
  }
}

export const REQUEST_STATUS = Object.freeze({
  loading: 'loading',
  success: 'success',
  fail: 'fail'
})
export type RequestStatusType = $Values<typeof REQUEST_STATUS>

export type GetStripeChargeType = {
  message?: string,
  error?: string
}

export type AnswerType = {
  index: number,
  text: string,
  genderPrefText?: 'Men' | 'Women' | 'Both',
  outcomes: string[]
}
export type QuizQuestionType = {
  index: number,
  icon: string,
  iconAlt: string,
  questionText: string,
  answers: AnswerType[],
  genderPref?: boolean
}
export type TieBreakersQuestionType = {
  index: number,
  icon: string,
  type: 'tiebreaker',
  questionText: string,
  vs: string[],
  answers: AnswerType[]
}
export type QuizResultSlidesType = {
  text: string,
  image: string
}
export type QuizResultsType = {
  [key: string]: {
    id: string,
    image: string,
    genderPref?: string,
    display_name: string,
    description: string
  }
}

export type QuizDataType = {
  quiz: QuizQuestionType[],
  tieBreakers: TieBreakersQuestionType[],
  results: QuizResultsType,
  resultHeader: string,
  resultSlides: QuizResultSlidesType[]
}

export type QuizType = 'trope-quiz' | 'hunk-quiz' | 'fantasy-quiz'

export type UserReferralDataType = {
  isSuccessfullyReferredAFriend: boolean
}

export type UserScoreCardConversionQuizResultsType = {
  [order: number]: {
    question: string,
    answer: string
  }
}

export type UserScoreCardHunkQuizType = {
  hunk_id: string,
  hunk_name: string,
  sexual_preference_answer: string,
  timestamp: number,
  results: UserScoreCardConversionQuizResultsType
}

export type UserScoreCardTropeQuizType = {
  series_id: string,
  series_name: string,
  sexual_preference_answer: string,
  timestamp: number,
  results: UserScoreCardConversionQuizResultsType
}
export type QuizGenderPreferenceType = 'Men' | 'Women' | 'Both'

export type CancelFlowType = 'cancel-flow-default' | 'cancel-flow-1' | 'cancel-flow-2' | 'cancel-flow-3' | 'cancel-flow-4'

export type AlgoTestAssignmentsType = 'default' | 'groupA' | 'groupB'

export type UtmParamsType = {utm_campaign: string, utm_medium: string, utm_source: string}

export type RecMetaDataType = {
  [id: string]: {
    id: string,
    badgeText?: string,
    nextTrackIdForImage?: string,
    imageUrl?: string
  }
}

export type DeepLinkType = {
  title: string,
  description: string,
  description2?: string,
  imageUrl: string,
  deepLinkUrl: string,
  deeplinkAccessibilityHint?: string,
  badgeText?: string,
  type?: "tag" | "series" | "category" | "link" | "track",
  id: string,
  bannerTitle?: string,
  bannerDescription?: string,
  bannerImage?: string,
  isLockOverlayVisible?: boolean
}

export type RecommendationV5Type = {
  trackId?: string,
  title: string,
  location: string,
  ids: string[], // tagIds | seriesIds | categoryIds | trackIds
  markUpData?: RecMetaDataType, // if we want additional info for client-side display, such as New, Your Top Pick, Actor Name, Liaten Again, etc
  description: string,
  badgeText?: string, // featured card
  isWellness?: 1 | 0,
  cta?: {
    name: string,
    deepLink: string
  },
  type?: | "favorites"
    | "tracks"
    | "categories"
    | "tags"
    | "series"
    | "books"
    | "cta" // ids type
    | "content", // used for logging dipsea content feature group
  displayType: | "featuredCard"
    | "actorBanner"
    | "horizontalMediumTrackRow"
    | "horizontalLargeTrackRow"
    | "circularRow"
    | "playableTrackRow"
    | "featuredSeriesCard"
    | "horizontalLargeSeriesRow"
    | "horizontalMediumBookRow"
    | "subscriptionButton"
    | "topFeaturedCard"
    | "lastFeaturedCard"
    | "onboardingButton"
    | "categoriesButtonRow"
    | "referCta"
    | "horizontalLargeSeriesOrTrackRow"
    | "engagementQuizButton"
    | "tagGroupDisplay"
    | "deeplinkTwoColButtons"
    | "discoverNavRow"
    | "discoverNavBlock"
    | "lockedQuizButton"
    | "deepLinkedCardsCarousel"
    | "deepLinkedLargeSquareCardsRow"
    | "deepLinkedMediumSquareCardsRow"
    | "deepLinkedSmallSquareCardsRow"
    | "deepLinkedCircularRow"
    | "wrappedButton"
    | "openWebLink"
    | "none",
  imageUrl?: string,
  imageAltText?: string,
  unlockContent?: boolean,
  items: DeepLinkType[],
  header?: string,
  subHeader?: string
}

export type UserRecommendationsV5Type = {
  userId?: string,
  recommendations: RecommendationV5Type[]
}

export type SearchRecommendationType = {
  title: string,
  location: string,
  ids: string[], // tagIds | seriesIds | categoryIds | trackIds
  type: "cta", // used for logging dipsea content feature group
  displayType: "deeplinkTwoColButtons",
  items: DeepLinkType[]
}

export type BookType = {
  id: string,
  title: string,
  artUrl: string,
  unlocked?: boolean,
  contextIds?: {
    [key: string]: number
  },
  createdAt?: number,
  description: string,
  shortDescription: string,
  searchTagIds?: {
    [key: string]: number
  },
  tagIds: {
    [key: string]: {
      index: number
    }
  },
  featured?: boolean,
  "image-100": string,
  "image-500": string,
  publishedAt: number,
  comingSoonDate?: number,
  slugs: {
    [key: string]: number
  },
  deleted?: boolean,
  chapterIds: {
    [key: string]: {
      index: number,
      readingTimeInSeconds: number,
      title: string,
      publishedAt: number,
      comingSoonText?: string,
      shortDescription: string,
      unlocked: boolean,
      characters: number,
      chapterId: string
    }
  },
  readingTimeInSeconds?: number,
  index?: number
}

export type BooksIndexType = {
  [key: string]: BookType
}

export type ChapterType = {
  id?: string,
  title: string,
  artUrl: string,
  bookId: string,
  chapterNumber: number,
  unlocked?: boolean,
  createdAt?: number,
  description: string,
  shortDescription: string,
  text: string,
  characters: number,
  publishedAt: number,
  comingSoonText?: number,
  deleted?: boolean,
  readingTime?: string,
  slugs?: {
    [key: string]: number
  },
  isFlameIconOnChapter?: boolean
}

export type ChaptersIndexType = {
  [key: string]: ChapterType
}

export type TagToBookItemType = {
  book_ids: {
    [key: string]: number
  }
}

export type TagToBookType = {
  [key: string]: TagToBookItemType
}

export type ScoredTrackType = {
  track_id: string,
  tracks: string[]
}

export type ScoredTracksType = {
  dateTime?: number,
  [track_id: string]: ScoredTrackType
}

export type ActorType = {
  id: string,
  trackIds: {
    [key: string]: 1
  },
  hunkIds: {
    [key: string]: 1
  }
}

export type ActorsIndexType = {
  [key: string]: ActorType
}

export type DipseaContentType = {
  tags: TagsObjectType,
  series: SerialsObjectType,
  contexts: CategoriesObjectType,
  tracks: TracksObjectType,
  tagsToTracks: TagToTrackType,
  sleepFilterTags: { [key: string]: { index: number } },
  guideCategory: GuideCategoryType,
  unlockedTracks: ObjectKeyIndexType,
  slugs: SlugsType,
  books: BooksIndexType,
  user?: UserType,
  role?: AdminEnumType,
  actors: ActorsIndexType,
  themes: ThemeObjectType
}
